.input-div {
  margin-bottom: 20px;
  text-align: left;
  max-width: 100%;
  flex: 1 !important;
  margin-top: 20px;
}
.input-text {
  background-color: white;
  height: 34px;
  text-indent: 10px;
}
label {
  font-size: 0.8rem;
  color: #b1b2b6;
}
.required {
  color: red;
}
.error-message {
  color: #da2a2a;
  font-size: 0.8rem;
}
input:focus {
  border: none;
}
