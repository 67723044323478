.list-div {
  ul{
    padding: 0;
  }
}
  .viewallnotfcn-div{
    margin-bottom: 10px;
    span{
      color: #2DBECD;
      padding: 10px;
    }
    cursor: pointer;
  }

.card-section{
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #E1E1EA;
  box-sizing:border-box;
  -moz-box-sizing:border-box;
  -webkit-box-sizing:border-box;
  padding: 0 10px;
  .card-sub{
    flex: 33.33%;
  }
  .card-full{
    flex: 1;
  }
  img{
    height: 30px;
    width: 30px;
    margin: 5px 5px 5px;
  }
  div{
    margin: 10px 2px 5px;
    display: flex;
   .subject-span{
    font-weight: bold;
    padding-left: 5px;
    width: 200px;
    margin-top: 2px;
    span:nth-child(2){
      font-weight: normal;
      display: block;
      padding-top: 0px;
    }
   }
  }
  .card-details{
    justify-content: space-between;
    span{
      width: 300px;
    }
  }
  .subj-div{
    display: flex;
    justify-content: flex-end;
    flex: 1;
    color: #000000;
    opacity: 0.8;
  }
  .center-text-detail{
    padding-top:8px;
  }
  .center-text{
    padding-top: 10px;
  }
}

.active{
  background: #F8F8FC;
}
.truncate-text{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.truncate-subtext{
  display: -webkit-box !important; /* Use flexbox layout */
  -webkit-box-orient: vertical; /* Set the orientation to vertical */
  -webkit-line-clamp: 2; /* Limit to 3 lines */
  line-clamp: 2;
  overflow: hidden; /* Hide overflowed content */
  text-overflow: ellipsis; /* Add ellipsis */
  white-space: pre-line !important;
  padding-left: 1px;
}



