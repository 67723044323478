.confirmation-text {
  text-align: center;
  font-size: 20px;
  margin-bottom: 15px;
}
.warning-text {
  text-align: center;
  font-size: 20px;
  margin-bottom: 15px;
  margin-top: -25px;
}
.ant-modal-content {
  min-height: 100px;
}
.ant-btn-danger {
  color: #fff;
  background-color: #ffc832 !important;
  text-transform: uppercase;
  font-size: 0.9rem;
  float: left;
  border-color: #ffc832 !important;
}
.ant-btn-danger:hover .ant-btn-danger:focus {
    color: #fff;
  background-color: #ffc832 !important;
  text-transform: uppercase;
  font-size: 0.9rem;
  float: left;
  border-color: #ffc832 !important;
}


.ok-button{
  margin: 0;
  position: absolute;
  top: 80%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
