.LD__Table--large {
  width: 100% !important;
  overflow: hidden !important;
  .dTtsRD {
    width: 100% !important;
  }
}
.table-no-results-content {
  text-align: center;
  padding: 20px 0;
}
.container-table {
  background: none;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}
.mainDivHeader {
  background: #595959;
  height: 50px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
  color: #fff;
  padding-left: 15px;
  padding-top: 10px;
}
.wrap-table {
  position: relative;
  justify-content: center !important;
  min-height: 100px;
  .div {
    .table {
     width: 100% !important;
    }
  }
  .LD__Table table tbody tr {
    background: white;
    margin-bottom: 12px !important;
  }
  @media (min-width: 1400px) {
    tbody {
      max-height: 380px !important;
    }
  }
  tbody {
    display: block;
    height: auto;
    max-height: calc(100vh - 315px) !important;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 10px
  }
  thead, tbody tr {
    td {
      vertical-align: middle;
    }
    display:table;
    width:100%;
    table-layout:fixed;
    margin: 3px !important;
  }
  thead {
    tr {
      box-shadow: none !important;
      th {
        vertical-align: middle !important;
        word-break: break-word;
        & > div {
          padding-top: 0px !important;
          padding-bottom: 0px !important;
        }
      }
    }
  }
  table {
    background: #F8F8FC;
  }
}
table {
  padding-top: 60px;
  justify-content: center;
  padding-left: 60px;
//   .bGwZaP{
//     padding: 22px 25px;
//   }
 }
thead {
  width: 100%;
  top: 0;
  left: 20px;
  color: gray;
  font-size: 14px;
  th {
    font-weight: 100;
  }
}
tr {
  margin-bottom: 10px !important;
  box-shadow: 1px 2px 5px rgba(193, 193, 193, 0.5);
}
th svg{
  fill: #8c8c8c;
}

tbody {
  line-height:1;
}
.user-main-content {
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  .LD__TablePagination {
    background: #F8F8FC;
    height: 35px;
    align-items: baseline;
    & > div {
      background: #F8F8FC;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .wrap-table tbody {
    max-height: calc(100vh - 382px) !important;
  }

}

@media screen and (max-width: 767px) {
  .container-table .wrap-table tr th {
    display: none;
  }

  .container-table .wrap-table, .container-table .wrap-table td, .container-table .wrap-table th {
    display: block;
    padding: 15px 5px;
  }

  .container-table td[data-th]:before  {
    content: attr(data-th);
    float: left;
    margin-right: 15px;
    font-weight: bold;
    width:40%;
  }
}