// ==========================================================================
// Forms Mixins
// ==========================================================================

// btn size helper
@mixin form-control() {
  .form-group {
    margin-bottom: $form-group-m-btm-base;
  }

  // Overall Form Control
  .form-control {
    display: block;
    width: 100%;
    padding: $input-padding-y $input-padding-x;
    font-size: $font-size-md;
    height: $input-height;
    line-height: $input-line-height;
    color: $input-color;
    background-color: $input-bg;
    background-image: none;
    background-clip: padding-box;
    border: $input-btn-border-width solid $input-border-color;
    @include border-radius();
    transition: $form-transition;

    // Focus
    &:focus {
      border-color: $input-border-focus-color;
      outline: none;
    }

    //Error
    &.error {
      border-color: $input-border-error-color;
      outline: none;
    }

    // Placeholder
    &::placeholder {
      color: $input-color-placeholder;
      opacity: 1;
    }

    // Disabled
    &:disabled,
    &[readonly] {
      background-color: $input-bg-disabled;
      opacity: 1;
    }

    &:disabled {
      cursor: $cursor-disabled;
    }

    // Unstyle the caret on `<select>`s in IE10+.
    &::-ms-expand {
      background-color: transparent;
      border: 0;
    }
  }

  // Base Form Label
  .form-label {
    margin-bottom: $input-label-margin-bottom;
    display: inline-block;

    .required {
      margin-left: 3px;
      font-weight: $font-weight-bold;
      color: $brand-action;
    }
  }

  // Base Form Description Styles
  .form-description {
    color: $input-description-color;
  }

  // Base Form Error Styles
  .form-error-message {
    color: $input-error-text-color;
    font-size: $input-error-text-font-size;
    margin-top: 5px;
    margin-left: 2px;
  }

  // Specific Styles for Small Form Groups
  .form-group-sm {
    margin-bottom: $form-group-m-btm-sm;

    .form-control {
      padding: $input-padding-y-sm $input-padding-x-sm;
      font-size: $font-size-sm;
    }

    .form-label {
      font-size: $input-label-font-size-sm;
    }

    .form-description {
      font-size: $input-label-font-size-sm;
    }
  }

  // Specific Styles for Large Form Groups
  .form-group-lg {
    margin-bottom: $form-group-m-btm-lg;

    .form-label {
      font-size: $input-label-font-size-lg;
    }
  }

  // Sizing styles for Inputs
  .form-control-sm {
    height: $input-height-sm;
    padding: $input-padding-y-sm $input-padding-x-sm;
    font-size: $font-size-sm;
  }

  .form-control-lg {
    height: $input-height-lg;
    padding: $input-padding-y-lg $input-padding-x-lg;
    font-size: $font-size-md;
  }
}


// React Select Utilities

@mixin size($width, $height)
{
	width: $width;
	height: $height;
}
@mixin square($size)
{
	@include size($size, $size);
}
@mixin border-top-radius($radius)
{
	border-top-right-radius: $radius;
	border-top-left-radius: $radius;
}
@mixin border-right-radius($radius)
{
	border-bottom-right-radius: $radius;
	border-top-right-radius: $radius;
}
@mixin border-bottom-radius($radius)
{
	border-bottom-right-radius: $radius;
	border-bottom-left-radius: $radius;
}
@mixin border-left-radius($radius)
{
	border-bottom-left-radius: $radius;
	border-top-left-radius: $radius;
}


// Vendor Prefixes

@mixin animation($animation)
{
	-webkit-animation: $animation;
	-o-animation: $animation;
	animation: $animation;
}

@mixin rotate($degrees)
{
	-webkit-transform: rotate($degrees);
	-ms-transform: rotate($degrees); // IE9 only
	-o-transform: rotate($degrees);
	transform: rotate($degrees);
}
@mixin transform($transform)
{
	-webkit-transform: $transform;
	-moz-transform: $transform;
	-ms-transform: $transform;
	transform: $transform;
}
@mixin Select-spinner($size, $orbit, $satellite)
{
	@include animation( Select-animation-spin 400ms infinite linear );
	@include square($size);
	box-sizing: border-box;
	border-radius: 50%;
	border: floor(($size / 8)) solid $orbit;
	border-right-color: $satellite;
	display: inline-block;
	position: relative;

}

@keyframes Select-animation-spin {
	to { transform: rotate(1turn); }
}
@-webkit-keyframes Select-animation-spin {
	to { -webkit-transform: rotate(1turn); }
}
