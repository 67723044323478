.vertical-line {
  overflow: hidden;
  .LD__Bubble{
    color:white;
  }
}

.vertical-line:after {
  content:"";
  display: inline-block;
  height: 0.9em;
  vertical-align: bottom;
  width: 100%;
  margin-right: -100%;
  margin-left: 10px;
  border-top: 1px solid #c4c4c55c;
}

.section-title {
  padding-right: 10px;
  font-weight: 900;
}

.accordion-section {
  padding: 12px 0;
  font-size: 15px;
}

.accordion-menu {
  height: auto;
  max-height: 285px;
  overflow: auto;
}

.accordion-menu._scroll {
  overflow-x: hidden;
}

.checkbox-filter div label {
  padding: 0px 10px;
  line-height: 1.2;
  margin: 5px 0px;
}
.checkbox-filter div svg {
  position: relative;
}