.device-style-icon {
  width: 18px;
  margin-right: 5%;
}
.fade-opacity {
  opacity: 0.4;
}
.device-certificate-modal {
  .ant-modal {
    width: 80% !important;
  }
}
.device-link-no-decoration.disabled {
  color: #ccc;
}
