// Set theme variables here
// eg: $brand-primary: blue;

// Import the base theme from sial-sass
@import './sial-sass/themes/default';
@import './nextconnect.scss';
@import './sial-sass/mixins';
@import './sial-sass/utils';

::-webkit-scrollbar {
  width: 7px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
::-webkit-scrollbar-thumb {
  background: rgb(196, 192, 192); 
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(122, 121, 121); 
}

.ReactModal__Content {
  z-index: 99;
}

.LD__Table table{
  width: 100% !important;
  background: #fff;
}
.LD__Table table tbody tr{
  margin: 20px;
}
.table-row-header {
  border-bottom: 1px solid $gray-lightest;
  padding-bottom: 10px;

  @include media-breakpoint-down(md, $grid-breakpoints) {
    display:none;
  }
}

.sortable-table-header {
  display: inline-block;
  color:  #6d6e71;
  font-weight: $font-weight-medium;
  padding-left: 8px;
  padding-right: 2px;
  font-size: 14px;
  word-wrap: break-word;
  cursor: pointer;
  div {
    @include media-breakpoint-down(lg, $grid-breakpoints) {
      padding-left: 2px;
      padding-right: 0;
    }
  }
}

.sortable-table-header div span:first-child {
  max-width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sort-icon-body {
  position: absolute !important;
  padding-top: 3px !important;
}

.listing-screen-title {
  font-size: $font-size-h5;
  font-weight: $font-weight-normal;
  color: $gray-medium;
  border-bottom: 1px solid $gray-lightest;
  border-radius: 2px 2px 0 0;
  background-color: #fff;
  @include media-breakpoint-down(lg, $grid-breakpoints) {
    border-bottom: none;
    padding: 10px 10px 0 14px;
  }
}

.header-row-sort-icon {
  min-width: 30%;
  padding-left: 4px;
  cursor: pointer;
  top:3px;
  position:absolute;
}
.sort-down-icon-style {
  position: relative;
  left: -10px;
}


.content-load-more {
  @include media-breakpoint-up(lg, $grid-breakpoints) {
    display:none;
  }

  @include media-breakpoint-down(md, $grid-breakpoints) {
    background-color: #2fbece;
    border: none;
    padding: 7px 15px;
    width: 100%;
    border-radius: 3px;
    color: #fff;
    margin-top: 15px;
    margin-bottom: 31px;
    font-size: 12px;
  }
}

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-clip {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
}
.listing-screen-filter-accordion{
  @include media-breakpoint-down(md, $grid-breakpoints) {
    width: calc(100% - 20px) !important;
    margin: 10px !important;
    height: calc(100% - 20px) !important;
    overflow: auto !important;
  }
}

.double-cirlce-icon {
  & {
    display: inline-block;

    /* shape */
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid black;

    /* position */
    text-align: center;
  }
  &:after {
    content: "";
    display: inline-block;

    /* shape */
    width: 4px;
    height: 4px;
    border-radius: 50%;

    /* position */
    vertical-align: top;
    position: relative;
    top: 2px;

    background-color: black;
  }

  &.green {
    border-color: $brand-success;
    &:after {
      background-color: $brand-success;
    }
  }
  &.red {
    border-color: $color-off;
    &:after {
      background-color: $color-off;
    }
  }
}

.ant-modal-title{
  font-size:1rem !important;
  line-height: 18px !important;
}

.ant-modal-body{
  padding:9px;
}

.ant-modal-content{
  border-radius:10px;
  white-space: pre-wrap;
}

.input-div label{
  font-size:1rem;
}

.top-div-right{
  margin-right:0px;
  &.feedback-buttons{
    margin-right: -10px;
    top: 3px;
  }
}

.footer-link-style{
  font-weight:bold;
}
