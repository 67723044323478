@import 'theme';

.view-more-certificate-details-modal-container {
    background-color: #ffffff;
    @include media-breakpoint-up(lg, $grid-breakpoints) {
        box-shadow: none;
        margin: 4;
    }
}
.my-test {
  .ant-modal-content {
    width: 900px;
    right: 200px;
  }
}
.view-more-certificate-details-modal-body {
    margin: rem-calc(20);
    font-weight: 500;
    font-size: 12px;
    color: #5b616e;
}
.view-more-certificate-details-header {
    border-bottom: solid 1px $gray-lightest;
    padding: rem-calc(10);
}

.view-more-certificate-details-header-text {
    padding-left: rem-calc(15);
    font-size: $font-size-h3;
    @include media-breakpoint-down(md, $grid-breakpoints) {
        font-size: $font-size-h4;
    }
}

.view-more-certificate-details-row{
  margin: 0;
  padding-bottom: 10px;
}
.view-more-certificate-details-row-col {
  width: 30%;
  float: left;
}
.view-more-certificate-details-content {
  margin-top: 20px;
  padding-bottom: 20px;
}
.view-more-certificate-details-title{
  font-weight: 500;
  font-size: 14px;
  color: #5b616e;
}
.view-more-certificate-details-data{
  font-weight: 500;
  font-size: 12px;
  color: #5b616e;
  padding-left: 10px;
}
.reset-margin {
  margin: 0;
}
.row {
    padding: 0;
  }

  .certificate-row-mobile-header {
  @include media-breakpoint-up(lg, $grid-breakpoints) {
    display:none;
  }

}

.certificate-value-serial {
  padding-left: 12px;
}

.modal-footer-container {
  flex-shrink: 0;
  text-align: right;
  padding: .2rem 1.1rem 0.2rem 0;
  background-color: #f5f5f5;
  margin-bottom: 10px;
  button {
    margin-left: 15px;
    min-width: rem-calc(85);
  }
}
.certificate-row{
  padding-left: 0;
  margin-bottom: 0;
  border-bottom: 1px solid $gray-lightest;
  padding-bottom: 2px;
}

.certificate-main-col {
  text-overflow: ellipsis;
  color: #666;
  font-size: 12px;
  padding: 0px 12px;
  overflow: hidden;
}
.certificate-col {
  text-overflow: ellipsis;
  color: #666;
  font-size: 12px;
  padding-left: 12px;
  width: 35%;
  overflow: hidden;
  @include media-breakpoint-down(md, $grid-breakpoints) {
    height: 30px;
    border: 1px solid #f2f2f2;
   padding-left: 15px;
    padding-right: 0px;
    padding-top: 15px;
    padding-bottom: 29px;
    position: relative;
  }
}
.modal-table-row-header {
   @include media-breakpoint-down(md, $grid-breakpoints) {
     display: none;
   }
}
.certificate-value {
  text-overflow: ellipsis;
  overflow: hidden;
}
.device-details-value {
  word-break: break-word;
}
.ca-issuer-value{
  @extend .text-truncate;
}
.view-more-certificate-details-table-row {
  width: 100%;
}
.ant-modal {
  width: 90% !important;
}