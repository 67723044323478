.btn-text-message {
  margin-left: 1rem;
  margin-top: 3px;
  font-size: 0.8rem;
  font-weight: 400;
  word-break: break-word;
}
.lmwzWF {
  display: flex !important;
  justify-content: inherit !important;
  padding: 0 1rem 0px 1rem;
}
.sc-fzqzEs {
  display: flex !important;
  justify-content: inherit !important;
}
.add-button {
  float: right;
  margin: 5px 2rem 5px -20px;
  background-color: grey;
}
.add-button:hover {
  background: #2dbecd;
}
.fKJkw {
  display: flex !important;
}
.sc-AxhUy {
  display: flex !important;
}
.add-button:first-child {
  display: flex !important;
}
.add-button  {
  div {
    display: flex !important;
    margin: 0;
    padding: 0 0.5rem;
    align-items: center;
  }
}
.eSwYtm {
  font-size: 0.9rem;
}