.upload-button {
  float: right;
  margin: 5px;
  background-color: grey;
}
.header_bg {
  width: 95%;
  
}
.org_name {
  width: 70%;
  float: left; 
}
.org_icon {
  width: 20%;
  float: left;
}



// *********************** Responsive Design **********************//
// ======================================================================

/*************************4k-2560px***********************/

@media screen and (min-width: 2100px) {
  .org_icon {
    width: 10%;
    float: left;
  }
}

/************************************* Mobile ***********************************/

@media screen and (max-width: 767px) {
  .org_icon {
    width: 6%;
    float: left;
  }
  .org_name {
    width: 45%;
    float: left; 
  }
  .button-bar [type='button'] {
    margin: 0 0.2rem 0 0.2rem;
  }
  .button-bar{
    padding: 0rem;
    // margin-top:0rem;
  }
  }