.notfication-lst {
  display: block;
  -webkit-transition: all 200ms;
  transition: all 200ms;
  cursor: pointer;
  &:hover {
    background-color: #efeff1;
  }
}

.highlight{
  background-color: #efeff1;
}

