.videoTitle {
    display: flex;
    align-items: flex-end;
    .titleGreen {
        color: rgb(1, 136, 76)
    }
    .titleGrey {
        color: rgb(169, 169, 186);
    }
}
.playButton {
    width: 18px;
    margin-right: 10px;
}
.videoPlayer {
    width: 100%;
}

.play-video-modal {
    .ant-modal-body {
        padding: 0px;
        margin-bottom: -9px;
    }
    .ant-modal-content {
        border-radius: 0px;
    }
}
.playVideo {
    .ant-modal-body {
        padding: 0px;
        margin-bottom: -9px;
    }
    .ant-modal-content {
        border-radius: 0px;
    }
}

@media (min-width: 768px) {
    .ant-modal-wrap {
        .ant-modal{
            &.playVideo {
                width: 710px !important;
            }
        }
    }
    .play-video-modal {
        .ant-modal {
            width: 710px !important;
        }
    }
}
