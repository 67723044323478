.checkbox-filter {
  &.horizontal {
    overflow: hidden;
  }
  &.horizontal > .checkbox-wrap {
    float: left;
  }
}
.checkbox-wrap {
  & {
    margin-right: 1.5rem;
  }
  &:last-child {
    margin-right: 0;
  }
  &.custom {
    min-width: 8.125rem;
    .filter-content {
      position: relative;
      display: grid;
      width: max-content;
      margin: 0px;
  }
  }
}
