// ==========================================================================
// Border Radius
// ==========================================================================

@mixin border-radius($br-left: $border-radius, $br-right: $border-radius) {
  border-top-left-radius: $br-left;
  border-top-right-radius: $br-right;
  border-bottom-right-radius: $br-right;
  border-bottom-left-radius: $br-left;
}
