// ==========================================================================
// FormField
// ==========================================================================

//== Import global variables and utilities
@import 'theme';

//== Component Specific Styles

//Global Form Element styles
.form-group {
  margin-bottom: $form-group-m-btm-base;
}

// Overall Form Control
.form-control {
  display: block;
  width: 100%;
  padding: $input-padding-y $input-padding-x;
  font-size: $font-size-md;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-image: none;
  background-clip: padding-box;
  border: $input-btn-border-width solid $input-border-color;
  @include border-radius();
  transition: $form-transition;

  // Focus
  &:focus {
    border-color: $input-border-focus-color;
    outline: none;
  }

  //Error
  &.error {
    border-color: $input-border-error-color;
    outline: none;
  }

  // Placeholder
  &::placeholder {
    color: $input-color-placeholder;
    opacity: 1;
  }

  // Disabled
  &:disabled,
  &[readonly] {
    background-color: $input-bg-disabled;
    opacity: 1;
  }

  &:disabled {
    cursor: $cursor-disabled;
  }

  // Unstyle the caret on `<select>`s in IE10+.
  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }
}

// Base Form Label
.form-label {
  font-size: 13px;
  margin-bottom: $input-label-margin-bottom;
  display: inline-block;
  color: $gray;

  .required {
    margin-left: 3px;
    font-weight: $font-weight-bold;
    color: $brand-action;
  }
}

// Base Form Description Styles
.form-description {
  color: $input-description-color;
  font-size: $input-label-font-size-sm;
  margin-top: 6px;
  line-height: 1.45;
}

// Base Form Error Styles
.form-error-message {
  color: $input-error-text-color;
  font-size: $input-error-text-font-size;
  margin-top: 6px;
  margin-left: 2px;
}

// Specific Styles for Small Form Groups
.form-group-sm {
  .form-label {
    font-size: $input-label-font-size-sm;
  }
}

// Specific Styles for Large Form Groups
.form-group-lg {
  .form-label {
    font-size: $input-label-font-size-lg;
  }
}

// Sizing styles for Inputs
.form-control-sm {
  padding: $input-padding-y-sm $input-padding-x-sm;
  font-size: $font-size-sm;
}

.form-control-lg {
  padding: $input-padding-y-lg $input-padding-x-lg;
  font-size: $font-size-md;
}


// Input Styles
.input {
  height: $input-height;
  &-sm {
    height: $input-height-sm;
  }
  &-lg {
    height: $input-height-lg;
  }
}

.error {
  border: 1px solid $brand-danger;
}


// Input Group styles

.input-group {
  position: relative;
  width: 100%;
  display: table;
  border-collapse: separate;

  .form-control {
    display: table-cell;
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0;
    @include border-radius($border-radius, 0);
    @include text-truncate();

    &:not(:first-child):not(:last-child) {
      border-radius: 0;
      border-left: 0;
      border-right: 0;
    }
    &:not(:first-child) {
      @include border-radius(0, $border-radius);
      border-left: 0;
    }
    &:not(:last-child) {
      @include border-radius($border-radius, 0);
      border-right: 0;
    }
  }
}


//Checkbox Styles

.form-check {
  position: relative;
  display: block;
  margin-bottom: $form-group-m-btm-base;

  // Move up sibling radios or checkboxes for tighter spacing
  + .form-check {
    margin-top: -6px;
  }

  &.disabled {
    .form-check-label {
      color: $gray-light;
      cursor: $cursor-disabled;
    }
  }
}

.form-check-label {
  color: $gray;
  margin-bottom: 0; // Override default `<label>` bottom margin
  cursor: pointer;
}

.form-check-input {
  margin-right: 5px;
  vertical-align: 1px;
}

.form-check-sm {
  .form-check-label {
    font-size: rem-calc(12);
  }
}

//Inline Form Checkbox and RadioCheckbox

.form-check-inline {
  position: relative;
  display: inline-block;
  margin-bottom: 12px;
  margin-right: 16px;

  &:last-of-type {
    margin-right: 0;
    margin-bottom: $form-group-m-btm-base;
  }

  &.disabled {
    .form-check-label {
      color: $gray-light;
      cursor: $cursor-disabled;
    }
  }
}


//////////////////////////////////////////////////////////////////////////////
///  React Select Styles  ////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////

.select-container {
  :global {
    .Select {
	     position: relative;

    	// handle disabled state
    	&.is-disabled > .Select-control {
    		background-color: $select-input-bg-disabled;
    		&:hover {
    			box-shadow: none;
    		}
    	}
    	&.is-disabled .Select-arrow-zone {
    		cursor: default;
    		pointer-events: none;
    	}
    }

    // base
    .Select-control {
    	background-color: $select-input-bg;
    	border-color: lighten($select-input-border-color, 5%) $select-input-border-color darken($select-input-border-color, 10%);
    	border-radius: $select-input-border-radius;
    	border: $select-input-border-width solid $select-input-border-color;
    	color: $select-text-color;
    	cursor: default;
    	display: table;
    	border-spacing: 0;
    	border-collapse: separate;
      font-size: $select-text-size;
    	height: $select-input-height;
    	outline: none;
    	overflow: hidden;
    	position: relative;
    	width: 100%;

    	&:hover {
    		box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    	}
    }

    .is-searchable {
    	&.is-open > .Select-control {
    		cursor: text;
    	}
    }

    .is-open > .Select-control {
    	@include border-bottom-radius( 0 );
    	background: $select-input-bg;
    	border-color: darken($select-input-border-color, 10%) $select-input-border-color lighten($select-input-border-color, 5%);

    	// flip the arrow so its pointing up when the menu is open
    	> .Select-arrow {
    		border-color: transparent transparent $select-arrow-color;
    		border-width: 0 $select-arrow-width $select-arrow-width;
    	}
    }

    .is-searchable {
    	&.is-focused:not(.is-open) > .Select-control {
    		cursor: text;
    	}
    }

    .is-focused:not(.is-open) > .Select-control {
    	border-color: $select-input-border-focus lighten($select-input-border-focus, 5%) lighten($select-input-border-focus, 5%);
    	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1), 0 0 5px -1px fade($select-input-border-focus,50%);
    }

    // placeholder
    .Select-placeholder,
    :not(.Select--multi) > .Select-control .Select-value {
    	bottom: 0;
    	color: $gray;
    	left: 0;
    	line-height: $select-input-internal-height;
    	padding-left: $select-padding-horizontal;
    	padding-right: $select-padding-horizontal;
    	position: absolute;
    	right: 0;
    	top: 0;

    	// crop text
    	max-width: 100%;
    	overflow: hidden;
    	text-overflow: ellipsis;
    	white-space: nowrap;
    }

    .has-value:not(.Select--multi):not(.is-focused) > .Select-control > .Select-value,
    .has-value.is-pseudo-focused:not(.Select--multi) > .Select-control > .Select-value {
    	.Select-value-label {
    		color: $select-text-color;
    	}
    	a.Select-value-label {
    		cursor: pointer;
    		text-decoration: none;

    		&:hover {
    			color: $select-link-hover-color;
    			text-decoration: underline;
    		}
    	}
    }


    // the <input> element users type in
    .Select-input {
    	// inherits `display: inline-block` from "react-input-autosize"
    	height: $select-input-internal-height;
    	padding-left: $select-padding-horizontal;
    	padding-right: $select-padding-horizontal;
    	vertical-align: middle;

      &:focus {
        outline: none;
      }

    	> input {
    		background: none transparent;
    		border: 0 none;
    		box-shadow: none;
    		cursor: default;
    		display: inline-block;
    		font-family: inherit;
    		font-size: inherit;
    		height: $select-input-internal-height;
    		margin: 0;
    		outline: none;
    		padding: 0;
    		-webkit-appearance: none;

    		.is-focused & {
    			cursor: text;
    		}
    	}

    }

    // fake-hide the input when the control is pseudo-focused
    .has-value.is-pseudo-focused .Select-input {
    	opacity: 0;
    }

    // fake input
    .Select-control:not(.is-searchable) > .Select-input {
    	outline: none;
    }

    // loading indicator
    .Select-loading-zone {
    	cursor: pointer;
    	display: table-cell;
    	position: relative;
    	text-align: center;
    	vertical-align: middle;
    	width: $select-loading-size;
    }
    .Select-loading {
    	@include Select-spinner($select-loading-size, $select-loading-color-bg, $select-loading-color);
    	vertical-align: middle;
    }


    // the little cross that clears the field
    .Select-clear-zone {
    	@include animation( Select-animation-fadeIn 200ms );
    	color: $select-clear-color;
    	cursor: pointer;
    	display: table-cell;
    	position: relative;
    	text-align: center;
    	vertical-align: middle;
    	width: $select-clear-width;

    	&:hover {
    		color: $select-clear-hover-color;
    	}
    }
    .Select-clear {
    	display: inline-block;
    	font-size: $select-clear-size;
    	line-height: 1;
    }
    .Select--multi .Select-clear-zone {
    	width: $select-clear-width;
    }


    // arrow indicator
    .Select-arrow-zone {
    	cursor: pointer;
    	display: table-cell;
    	position: relative;
    	text-align: center;
    	vertical-align: middle;
    	width: ($select-arrow-width * 5);
    	padding-right: $select-arrow-width;
    }

    .Select-arrow {
    	border-color: $select-arrow-color transparent transparent;
    	border-style: solid;
    	border-width: $select-arrow-width $select-arrow-width ($select-arrow-width / 2);
    	display: inline-block;
    	height: 0;
    	width: 0;
    }
    .is-open .Select-arrow,
    .Select-arrow-zone:hover > .Select-arrow {
    	border-top-color: $select-arrow-color-hover;
    }

    // Animation
    // ------------------------------
    // fade in
    @-webkit-keyframes Select-animation-fadeIn {
    	from { opacity: 0; }
    	to { opacity: 1; }
    }
    @keyframes Select-animation-fadeIn {
    	from { opacity: 0; }
    	to { opacity: 1; }
    }

    // wrapper around the menu
    .Select-menu-outer {
    	// Unfortunately, having both border-radius and allows scrolling using overflow defined on the same
    	// element forces the browser to repaint on scroll.  However, if these definitions are split into an
    	// outer and an inner element, the browser is able to optimize the scrolling behavior and does not
    	// have to repaint on scroll.
    	@include border-bottom-radius( $select-input-border-radius );
    	background-color: $select-input-bg;
    	border: 1px solid $select-input-border-color;
    	border-top-color: mix($select-input-bg, $select-input-border-color, 50%);
    	box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    	box-sizing: border-box;
    	margin-top: -1px;
    	max-height: $select-menu-max-height;
    	position: absolute;
    	top: 100%;
    	width: 100%;
    	z-index: $select-menu-zindex;
    	-webkit-overflow-scrolling: touch;
    }


    // wrapper
    .Select-menu {
    	max-height: ($select-menu-max-height - 2px);
    	overflow-y: auto;
    }


    // options
    .Select-option {
    	box-sizing: border-box;
    	background-color: $select-option-bg;
    	color: $select-option-color;
    	cursor: pointer;
    	display: block;
      font-size: $select-text-size;
    	padding: $select-padding-vertical $select-padding-horizontal;

    	&:last-child {
    		@include border-bottom-radius( $select-input-border-radius );
    	}

    	&.is-focused {
    		background-color: $select-option-focused-bg;
    		color: $select-option-focused-color;
    	}

    	&.is-disabled {
    		color: $select-option-disabled-color;
    		cursor: default;
    	}

    }

    // no results
    .Select-noresults {
    	box-sizing: border-box;
    	color: $select-noresults-color;
    	cursor: default;
    	display: block;
      font-size: $select-text-size;
    	padding: $select-padding-vertical $select-padding-horizontal;
    }

    // Multi-Select Base

    .Select--multi {

    	// add margin to the input element
    	.Select-input {
    		vertical-align: middle;
    		// border: 1px solid transparent;
    		margin-left: $select-padding-horizontal;
    		padding: 0;
    	}

    	// reduce margin once there is value
    	&.has-value .Select-input {
    		margin-left: $select-item-gutter;
    	}

    	// Items
    	.Select-value {
    		background-color: $select-item-bg;
    		border-radius: $select-item-border-radius;
    		border: 1px solid $select-item-border-color;
    		color: $select-item-color;
    		display: inline-block;
    		font-size: $select-item-font-size;
    		margin-left: $select-item-gutter;
    		margin-top: $select-item-gutter;
    		vertical-align: top;
    	}

    	// common
    	.Select-value-icon,
    	.Select-value-label {
    		display: inline-block;
    		vertical-align: middle;
    	}

    	// label
    	.Select-value-label {
    		@include border-right-radius( $select-item-border-radius );
    		cursor: default;
    		padding: $select-item-padding-vertical $select-item-padding-horizontal;
    	}
    	a.Select-value-label {
    		color: $select-item-color;
    		cursor: pointer;
    		text-decoration: none;

    		&:hover {
    			text-decoration: underline;
    		}
    	}

    	// icon
    	.Select-value-icon {
    		cursor: pointer;
    		@include border-left-radius( $select-item-border-radius );
    		border-right: 1px solid $select-item-border-color;

    		// move the baseline up by 1px
    		padding: ($select-item-padding-vertical - 1) $select-item-padding-horizontal ($select-item-padding-vertical + 1);

    		&:hover,
    		&:focus {
    			background-color: $select-item-hover-bg;
    			color: $select-item-hover-color;
    		}
    		&:active {
    			background-color: $select-item-border-color;
    		}
    	}

    }

    .Select--multi.is-disabled {
    	.Select-value {
    		background-color: $select-item-disabled-bg;
    		border: 1px solid $select-item-disabled-border-color;
    		color: $select-item-disabled-color;
    	}
    	// icon
    	.Select-value-icon {
    		cursor: not-allowed;
    		border-right: 1px solid $select-item-disabled-border-color;

    		&:hover,
    		&:focus,
    		&:active {
    			background-color: $select-item-disabled-bg;
    		}
    	}
    }

    // Select Box Sizing
    .select-size-xs, .select-size-sm {
      .Select-control {
        font-size: $select-text-size-sm;
      	height: $select-input-height-sm;
      }
      // placeholder
      .Select-placeholder,
      :not(.Select--multi) > .Select-control .Select-value {
      	line-height: $select-input-internal-height-sm;
        padding-left: $select-padding-horizontal-sm;
      	padding-right: $select-padding-horizontal-sm;
      }
      // the <input> element users type in
      .Select-input {
      	// inherits `display: inline-block` from "react-input-autosize"
      	height: $select-input-internal-height-sm;
      	padding-left: $select-padding-horizontal-sm;
      	padding-right: $select-padding-horizontal-sm;

      	> input {
      		height: $select-input-internal-height-sm;
      	}
      }

      .Select-arrow-zone {
      	line-height: 1.2;
      }

      .Select-option {
        font-size: $select-text-size-sm;
        padding: $select-padding-vertical-sm $select-padding-horizontal-sm;
      }
      // no results
      .Select-noresults {
        font-size: $select-text-size-sm;
        padding: $select-padding-vertical-sm $select-padding-horizontal-sm;
      }
    }

    .select-size-lg {
      .Select-control {
        font-size: $select-text-size-lg;
      	height: $select-input-height-lg;
      }
      // placeholder
      .Select-placeholder,
      :not(.Select--multi) > .Select-control .Select-value {
      	line-height: $select-input-internal-height-lg;
        padding-left: $select-padding-horizontal-lg;
      	padding-right: $select-padding-horizontal-lg;
      }
      // the <input> element users type in
      .Select-input {
      	// inherits `display: inline-block` from "react-input-autosize"
      	height: $select-input-internal-height-lg;
      	padding-left: $select-padding-horizontal-lg;
      	padding-right: $select-padding-horizontal-lg;

      	> input {
      		height: $select-input-internal-height-lg;
      	}
      }

      .Select-option {
        font-size: $select-text-size-lg;
        padding: $select-padding-vertical-lg $select-padding-horizontal-lg;
      }
      // no results
      .Select-noresults {
        font-size: $select-text-size-lg;
        padding: $select-padding-vertical-lg $select-padding-horizontal-lg;
      }
    }
  }
}

// ANTD Select Component
.ant-select.ant-select-single.ant-select-show-arrow {
  width: 100%;
}
