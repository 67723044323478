.filter-content {
  & {
    position: relative;
    display: block;
    margin-bottom: 1rem;
  }
  &.disabled > .filter-label {
    color: rgba(120, 120, 120, 0.5)
  }
}
.filter-label {
  font-size: .875rem;
  color: #787878;
  margin-bottom: 0;
  cursor: pointer;
  font-weight: lighter;
  font-family: inherit;
  text-transform: capitalize;
  vertical-align: top;
}
.filter-checkbox-input {
  margin: 0.156rem 0.3rem 0 0;
  vertical-align: top;
}
