@import 'theme';

.form-wrap {
    padding: 0 2rem;
    text-align: center;
}

.invite-user-btns{
    float: right;
}
.star-rating {
    margin-top: 8px;
}

.user-icon {
    margin-bottom: 8px;
}
.user-name{
    position: relative;
}
.help-text {
    position: relative;
    margin: 10px 0 25px 0px;
    font-size: 14px;
}