.exampleStyle {
  border: solid 1px #00adff;
  padding: 3%;
}
.examplblStyle {
  font-size: 7px;
  text-align: center;
  margin-top: 8%;
}
.phoneContent {
  width: 14rem;
  padding-bottom: 22%;
  font-size: 0.8rem;
}
.phoneList {
  // list-style-type: disc;
  display: flex;
  flex-direction: column;
  margin: 0;
  flex: end;
  padding: 0 0 0 1rem;
}
.phoneSuggestion {
  width: 100%;
  text-align: center;
}
.phoneFormatlbl {
  width: 28%;
  float: left;
}
.phoneFormatCountrycode {
  width: 30%;
  float: left;
}
.phoneFormatMinus {
  width: 5%;
  float: left;
}
.phoneFormatPhoneNumber {
  width: 30%;
  float: left;
}
.list__style {
  height: 0.3rem;
  width: 0.3rem;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.6rem;
}
