.org_additional_onfo {
  margin-right: 10%;    
}
.tooltip1 {
    padding: .400rem;
    .tootltip-title{
      font-size: 0.6875rem;
      font-weight: 600;
    }
    .tooltip-row {
      font-size: 0.6875rem;
      width: 100%;
  
      .tooltip-label, .tooltip-value {
        display: inline-block;
      }
      .tooltip-label:after {
        content: ':';
      }
      .tooltip-value {
        font-weight: 600;
        padding-left: 0.3125rem;
      }
    }
  }