  .expand-div {
    z-index: 100;
    cursor: pointer;
    max-width: 31px;
    height: 28px;
    background-color: #ffc832;
    padding: 3px;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 3px;
  }
  .expand-div1 {
    z-index: 100;
    cursor: pointer;
    max-width: 31px;
    height: 28px;
    background-color:#f5f9ff;;
    padding: 3px;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 3px;
  }
  .content {
    flex: 2;
    padding: 0 20px;
    /* max-width: 120px; */
  }
  h2.heading-text,
  p.sub-heading {
    margin: 0;
    color: #b1b2b4;
    .LD__Breadcrumbs {
      .epBBzT {
        pointer-events: none;
      }
    }
  }
  h2.heading-text {
    margin-top: 35px;
    color: #2dbecd;
    font-size: 20px
  }
  .left-right-flex {
    display: flex;
    justify-content: space-between;
    // margin-bottom: 1rem;
    .header-title {
      margin-left: 15px;
    }
  }

  .header_bg {
    width: 100% !important;
  }

  .header-container {
    position: fixed !important;
    z-index: 100;
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .side-bar-container {
     z-index: 100;
     margin-top: 3.2rem;
  }

  .body-content-container {
    margin-top: 3.2rem;
    left: 22px !important;
    position: relative;
    margin-bottom: 2%;
  }

  .margin-change{
    margin-left: 35px;
  }
  .header-top {
    margin-left: 0 !important;
    margin-right: 0 !important;

    .site-layout-background {
      overflow-x: hidden !important;
    }
  }
    // *don't declare any styles*

// --------------------------------------------------------------------------//
@media screen and (min-width: 1827px){
  .body-content-container {
    left: 22px !important;
  }
}
/*************************4k-2560px***********************/

@media screen and (min-width: 2100px) {
  .body-content-container {
    left: -25px !important;
  }
  main.ant-layout-content.site-layout-background {
    max-width: 100% !important;
}
}

// 0-767 - mobile ****768-1024 -ipad *****1025-above All web********//

/************************************* Ipad ***********************************/

@media screen and (min-width: 768px) and (max-width: 1024px) {
      .body-content-container {
        left: 0px !important;
        margin-bottom: 2%;
    }
    main.ant-layout-content.site-layout-background {
      margin-top: 0px !important;
      max-width: 100% !important;
  }
  .blank-div{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 111;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
  }
  .content {   
    padding: 0 10px;
  }  
}

/************************************* Mobile ***********************************/

@media screen and (max-width: 767px) {
  .body-content-container {
    left: 0px !important;
    margin-bottom: 7%;
}
main.ant-layout-content.site-layout-background {
  margin-top: 0px !important;
  max-width: 100% !important;
}
.blank-div{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 111;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}
.content {
  padding: 0 10px;
}
}