.loader-container {
  position: fixed;
  top: 0; bottom: 0; left: 0; right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;
  background: rgba(45, 190, 205, 0.52);
  background: linear-gradient(-180deg , rgba(45, 190, 205, 0.53) 0%, rgba(15, 105, 175, 0.45) 100%);
  overflow: hidden;
  font-family: 'Montserrat', sans-serif;
  background-attachment: fixed;
}

.row{
  position: relative;
  width: 100%;
  padding: 100px 15px 150px 15px;
}
.inner {
  max-width: 1024px;
  margin: 0 auto;
  text-align: center;
}

/*main element*/
.pulse-icon{
  position: relative;
  display: inline-block;
}
/*icon styling here*/
.pulse-icon .icon-wrap{
  animation: beat .5s infinite alternate;
  position: relative;
  width: 60px;
  height: 60px;
  box-shadow: 0 12px 12px 0 rgba(0,0,0,0.1);
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  color: #6A1B9A;
  background: #efefef;
  z-index: 2;
}
.pulse-icon .icon{
  line-height: 60px;
  font-size: 1.75em;
  animation: grow-shrink 4s ease-out infinite;
}

/*container of all circles and pulses*/
.pulse-icon .elements{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.pulse-icon .circle{
  border-radius: 50%;
  position: absolute;
}
.pulse-icon .circle-inner{
  width: 80px;
  height: 80px;
  top: -10px;
  left: -10px;
  background: linear-gradient(-180deg, rgba(255,255,255,0.12) 0%, rgba(255,255,255,0.1) 100%);
  animation: grow-circles 2s linear infinite both;
}
.pulse-icon .circle-outer{
  width: 100px;
  height: 100px;
  background: linear-gradient(-180deg, rgba(255,255,255,0.12) 0%, rgba(255,255,255,0.1) 100%);
  top: -20px;
  left: -20px;
  animation: grow-circles 3s linear infinite both;
}

/*series of x pulses of differing sizes*/
.pulse-icon .pulse{
  position: absolute;
  animation: pulse-wave 4s linear infinite both;
  border-radius: 50%;
}
.pulse-icon .pulse-1{
  border: solid 1px rgba(255,255,255,0.25);
  width: 200px;
  height: 200px;
  top: -70px;
  left: -70px;
}
.pulse-icon .pulse-2{
  border: solid 1px rgba(255,255,255,0.35);
  width: 300px;
  height: 300px;
  top: -120px;
  left: -120px;
}
.pulse-icon .pulse-3{
  border: solid 1px rgba(255,255,255,0.45);
  width: 400px;
  height: 400px;
  top: -170px;
  left: -170px;
}

/*animation for the inner and outer wave items to grow*/
@keyframes grow-circles{
  0%{
    opacity: 0.85;
    transform: scale(1.0);
  }
  25%{
    opacity: 1;
    transform: scale(1.3);
  }
  50%{
    opacity: 0.85;
    transform: scale(1.0);
  }
  75%{
    opacity: 1;
    transform: scale(1.3);
  }
  100%{
    opacity: 0.85;
    transform: scale(1.0);
  }
}



/*animation for the icon to slightly grow and shrink*/
@keyframes grow-shrink{
  0%{
    transform: scale(1);
  }
  30%{
    transform: scale(1.2);
  }
}
@keyframes beat{
  to { transform: scale(.9); }
}
