@import 'theme';

.user-rating-form-container {
    background-color: #ffffff;
    @include media-breakpoint-down(md, $grid-breakpoints) {
        margin-left: -10px;
        margin-right: -10px;
    }
    @include media-breakpoint-up(lg, $grid-breakpoints) {
        box-shadow: none;
        margin: 0;
    }
}

.add-user-form-body {
    margin: rem-calc(15);
}
.add-user-header {
    border-bottom: solid 1px $gray-lightest;
    margin-bottom: rem-calc(30);
}
.invite-user-btns{
    float: right;
}

.add-user-header-text {
    padding-left: rem-calc(15);
    font-size: $font-size-h3;
    @include media-breakpoint-down(md, $grid-breakpoints) {
        font-size: $font-size-h4;
    }
}
