@import 'theme';
.remember {
  display: flex;
  flex-flow: row wrap;
  align-content: space-between;
  justify-content: space-between;
}
.div-img-center {
  padding: 5%;
  text-align: center;
  opacity: 0.9;
  color: white;
}

.ant-select-show-arrow {
  width: 100%;
  border: 1px #d9d9d9 solid;
}
.ant-select-selector {
  background-color: #f8f8fc !important;
}
.input-text {
  background-color: #f8f8fc;
  height: 34px;
  text-indent: 10px;
  border: 1px #d9d9d9 solid;
}
.merck-welcome-badge {
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  position: absolute;
  padding: 0.5rem 1.5rem;
  border-radius: 0px !important;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  font-size: 20px !important;
  background-color: #2badbb !important;
  color: white;
}
.form-wrap {
  margin-top: 2rem;
}
.button-bar {
  padding: .425rem 1rem;
  height: 45px;
  overflow: hidden;
  text-align: right;
  @include media-breakpoint-down(sm, $grid-breakpoints) {
    height: auto;
  }

  [type='button'],
  [type='submit'] {
    min-width: 5rem;
    @include media-breakpoint-down(sm, $grid-breakpoints) {
      width: 100%;
      margin: 0 0 0.9375rem 0;
    }
  }
  [type='button'] {
    margin: 0 0.9375rem 0 0;
    @include media-breakpoint-down(sm, $grid-breakpoints) {
      margin: 0 0 0.9375rem 0;
    }
  }
  [disabled] {
    cursor: not-allowed;
  }
}
.partner-type-required {
  color: #f24949;
  font-size: .75rem;
}

.ant-modal-wrap {
  bottom: 27px;
}