@import "theme";
@import "theme";
@mixin flexAlignCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-carousel-div {
  padding: 0;
  margin: 0;
  max-width: 100% !important;
  [class~="ant-carousel"] {
    [class~="slick-slide"] {
      text-align: center;
      height: 300px !important;
      line-height: 160px !important;
      overflow: hidden !important;
      max-width: 100% !important;
    }
    [class~="slick-list"] {
      max-width: 100% !important;
      max-height: 300px !important;
    }
  }
  .input-div {
    max-width: 70%;
    @include flexAlignCenter;
    div {
      flex: 5;
    }
    label {
      flex: 0 1 auto;
      margin-right: 0.6rem;
    }
  }
}

.ant-carousel .slick-slide h3 {
  color: #fff;
}
.user-filter-wrap {
  border: 0.5px solid gainsboro;
  @include media-breakpoint-down(md, $grid-breakpoints) {
    height: 100%;
  }
}
.news-feed-heading {
  padding: 0;
  margin: 13px 0px 0px 13px;
  font-size: 18px;
  font-weight: 500;
  color: #503291;
}
.arrow-display {
  display: flex;
  margin-bottom: 10px;
}
.slick-list {
  width: 900px !important;
}
.slick-dots {
  .slick-dots-bottom {
    height: 17px !important;
    align-items: center;
    background-color: #2dbecd;
    bottom: 0px;
    // margin-right: 35%;
    width: 50%;
    position: initial;
  }
}
.slick-dots-bottom {
  height: 17px !important;
  align-items: center;
  background-color: #2dbecd;
  bottom: 0px;
  margin-left: 25% !important;
  width: 40%;
  position: initial !important;
}

.display-select {
  display: flex;
  justify-content: space-between;
}
.input-div {
  max-width: 100%;
}
.ant-carousel .slick-dots li button {
  height: 5px;
}
.no__news {
  @include flexAlignCenter;
  position: relative;
  width: 100%;
  height: 150px;
}
.no__news__found {
  position: absolute;
  text-align: center;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -56%);
  font-size: 1.5rem;
  width: 100%;
}




// *********************** Responsive Design **********************//
//=======================================================================

/************************************* Mobile ***********************************/

@media screen and (max-width: 767px) {

.ant-carousel-div {
  .input-div {
    max-width: 100%;
  
  }
}
.ant-modal-wrap {
  bottom: 20px;
}
}