
//== Component Specific Styles
.pagination {
  display: flex;
}
.pagination-items-container {
  border: 1px solid #ddd;
  width: 40px;
  padding-top: 7px;
  padding-left: 10px;
}
.pagination-items {
  padding: 5px;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}
.page-number {
  width: 36px;
  text-align: center;
  border: 2px solid rgb(221, 221, 221);

}
.show-first-icon {
   margin-top: -2px;
}
.show-prev-icon {
  margin-top: -3px;
  margin-right: 10px;
}
.show-next-icon {
  margin-top: -3px;
  margin-left: 10px;
}
.show-last-icon {
  margin-top: -2px;
}
