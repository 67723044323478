@import 'theme';

.div-card-center {
  padding: 5%;
  text-align: center;
  opacity: 0.9;
}
.form-active-card {
  max-width: 470px !important;
  height: 320px !important;
}
.form-title {
  padding: 10px !important;
  padding: 4px 10px 10px 10px !important;
  border-radius: 2px !important;
  font-size: 20px !important;
  background-color: #2badbb !important;
  margin-bottom: 20px !important;
}
.input-div {
  margin-bottom: 20px;
  text-align: left;
}
.downlod-wrapper{
  box-shadow: none;
  margin: 0px;
}
.download-certificates-title{
  font-weight: 400;
  border-bottom: 1px solid #f3f3f2;
  padding: 20px;
}
.download-buttons-wrapper {
  min-height: 150px;
  .download-button {
    width: 100%;
    padding: 15px 10px;
    border: 1px solid #ebeced;
    font-size: 14px;
    cursor: pointer;
    margin-bottom: 5px;
    .icon {
      color: #14c0d0;
      float: right;
    }
  }
}
.add-user-form-banner {
  display: block;
  height: 56px;
  padding: 10px;
}

.invite-user-btns{
  float: right;
}
.button-style {
  color: $gray-dark;
  border: 1px solid $gray-light;
}