//== Component Specific Styles
//==== DEVICES ===

.sort-up-style {
  position: relative;
  left: 4px;
  bottom: 1px;
}
.sort-down-style {
  position: absolute;
  left: 4px;
}

.device-header-height {
  color: #666;
  font-size: 13px;
}
.icon-white {
  color: #503291;
}
.user-content {
  padding-left: 0;
  margin: 0;
}

.device-col-fix {
  padding-left: 0 !important;
  padding-right: 0 !important;
  max-width: 33px;
}
.user-mob-morebtn {
  float: left;
}

.device-filter-content {
  position: relative;
  display: block;
  margin-bottom: 1rem;
}
.device-filter-label {
  font-size: 0.75rem;
  color: #545454;
  margin-bottom: 0;
  cursor: pointer;
}
.device-filter-checkbox-input {
  margin-right: 5px;
  vertical-align: 1px;
}
.device-sort-icons {
  width: 20px;
}
.device-sort-icon-direction {
  width: 100%;
}
.device-connected-grid {
  padding-right: 5px;
}
// Device sort filter pop up
.device-sort-icon {
  display: flex;
  flex-direction: column;
  float: left;
  margin-right: 15px;
}

// User filter
.filter-content {
  position: relative;
  display: block;
  margin-bottom: 1rem;
}
.filter-label {
  font-size: 0.875rem;
  color: #787878;
  margin-bottom: 0;
  cursor: pointer;
  font-weight: lighter;
  font-family: inherit;
  text-transform: capitalize;
}
.filter-checkbox-input {
  margin-right: 10px;
  vertical-align: -2px;
}
.user-filter-wrap {
  border: 0.5px solid gainsboro;
}
//== USER ROW
// Header

// Add user hide internal and external

.adduserradio {
  display: none;
}

// ROW
.user-row {
  padding-left: 0;
  margin-bottom: 0;
}
.files-row-main {
  border-bottom: 1px solid #f2f2f2;
}
.user-value {
  text-overflow: ellipsis;
  overflow: hidden;
}
.delete-value {
  padding-left: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
}
// Column
.user-main-col {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.user-listing-name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.user-listing-email {
  text-overflow: ellipsis;
  overflow: hidden;
}

.user-col {
  text-overflow: ellipsis;
  color: #666;
  font-size: 13px;
}

.ducument-name-style {
  cursor: pointer;
}
.LD__TablePagination {
  height: 50px;
}

.user-main-content{
  box-shadow:
      0 2.8px 2.2px rgba(0, 0, 0, 0.034),
      0 6.7px 5.3px rgba(0, 0, 0, 0.048),
      0 12.5px 10px rgba(0, 0, 0, 0.06),
      0 22.3px 17.9px rgba(0, 0, 0, 0.072),
      0 41.8px 33.4px rgba(0, 0, 0, 0.086),
      0 100px 80px rgba(0, 0, 0, 0.12)
    ;
}