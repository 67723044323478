.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.5s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.site-layout .site-layout-background {
  background: #f5f9ff !important;
  overflow-y: hidden;
  overflow-x: hidden;
}
.ant-layout-sider {
  background: #fff !important;
  ul {
    background: #fff !important;
    .ant-menu-item-selected {
      background: #fff !important;
    }
    li span {
      color: #2bdecd;
      margin-left: 7px;
    }
  }
}

.expanded-sidebar {
  min-width: unset !important;
  max-width: unset !important;
  flex: 0 0 170px !important;
  // width: 15% !important;
}

.expand-icon {
  width: 23px;
  height: 15px;
  vertical-align: inherit;
}
h2.heading-text,
p.sub-heading {
  margin: 0;
}
h2.heading-text {
  margin-top: 35px;
}
.left-right-flex {
  display: flex;
  justify-content: space-between;
}
.btn.btn-blue {
  background-color: #2dbecd;
  border: 0;
  border-radius: 5px;
  color: #fff;
  text-transform: uppercase;
  padding: 10px;
}
.acc-container {
  border-radius: 5px;
  overflow: hidden;
  margin-top: 15px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  .acc-btn {
    background-color: #000;
    color: #fff;
    padding: 10px 20px;
    p {
      margin: 0;
    }
    &.active {
      background-color: yellow;
      color: #000;
    }
  }
  .acc-content {
    .acc-padding {
      padding: 20px;
    }
    p {
      margin: 0;
    }
  }
}

.nav-svg {
  width: 14px;
  margin-right: 7px;
  height: 14px;
  stroke: #aaa;
}
.nav-svg-logout {
  width: 20px;
  margin-right: 10px;
  height: 24px;
  stroke: red;
  margin-bottom: -12px;
}
.ant-menu-item-active,
.ant-menu-item-selected {
  .nav-svg {
    stroke: #fff;
    filter: brightness(-150%);
    path {
      stroke: #fff !important;
    }
  }
}
.ant-menu-item-selected {
  position: relative;
  z-index: 0;
  &::before {
    content: "";
    background-repeat: no-repeat;
    background-image: url("../../static/icons/menu-highlight-drop.svg");
    background-size: contain;
    background-position: center center;
    position: absolute;
    left: 16px;
    width: 30px;
    height: 30px;
    z-index: -1;
    top: 2px;
  }
}
.ant-menu li:last-of-type {
  top: 20px;
}
.side-bar-box {
  box-shadow:
    0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048),
    0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072),
    0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
}
.ant-layout-sider-collapsed{
  box-shadow:
      0 2.8px 2.2px rgba(0, 0, 0, 0.034),
      0 6.7px 5.3px rgba(0, 0, 0, 0.048),
      0 12.5px 10px rgba(0, 0, 0, 0.06),
      0 22.3px 17.9px rgba(0, 0, 0, 0.072),
      0 41.8px 33.4px rgba(0, 0, 0, 0.086),
      0 100px 80px rgba(0, 0, 0, 0.12)
    ;
}
ul.ant-menu.ant-menu-dark.ant-menu-root.ant-menu-inline #vertical_menu {
  top: 56px;
 left: 160px;
 text-align: center;

 }
 ul.ant-menu.ant-menu-dark.ant-menu-inline-collapsed.ant-menu-root.ant-menu-vertical #vertical_menu {
  top: 56px;
 left: 60px;
 text-align: center;
 

 }
.sidebar-menus {
  height: 75%;
  .vertical-dropdown {
    #vertical_menu {
      top: 56px;
      left: 180px;     
      text-align: center;
      border-radius: 0px 12px 12px 0px;
      box-shadow: 7px 5px 15px rgba(0, 0, 0, 0.32);
      .drag-menu-header {
        text-align: left;
      }
      .LD__Button {
        margin-top: 5px;
      }
      .movable-item {
        // padding-top: 20px;
        display: flex;
      }
    }
  }
}

.sidebar-menus:hover{
  overflow-y: auto;
  overflow-x: hidden;
}
.ant-menu-inline-collapsed .ant-menu-item-selected {
  &::before {
    background-position: 7px 0;
  }
}
.ant-menu-inline-collapsed > .ant-menu-item .nav-svg + span {
  display: inline-block;
  max-width: 0;
  opacity: 0;
}
.ant-menu-dark.ant-menu-inline {
  // height: 100vh !important;
  // min-height: 520px !important;
  // height: auto;
}

.ant-layout-sider-children {
  // height: auto;
}
ul.sideber-nav {
  margin: 10px 0;
  padding: 0;
}
ul.sideber-nav li {
  text-align: center;
  display: block;
  position: relative;
}
ul.sideber-nav li img {
  width: 34px;
  display: block;
  margin: 0 auto;
}
ul.sideber-nav li img.merck-logo-inside-drop {
  height: 14px;
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  margin: auto;
  top: 19px;
  width: auto;
}
.sidebar-close {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 99;
  color: white;
  font-weight: 900;
  font-size: 1.1rem;
  cursor: pointer;
}

.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-item,
.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-submenu
  > .ant-menu-submenu-title,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0 26.5px !important;
}
.ant-menu-inline-collapsed > .ant-menu-item .nav-svg-logout + span {
  display: inline-block;
  max-width: 0;
  opacity: 0;
}
.ant-menu-dark .ant-menu-item-selected > span > a {
  color: grey ;
}
.ant-menu-dark .ant-menu-item > span > a {
  color: grey !important;
}
.ant-menu-dark .ant-menu-item > span > a:hover {
  color: grey;
}
.ant-menu-item-active > span > a {
  color: grey !important;
}

.side-content-elipsis{
  font-size: 35px !important;
  margin-left: 52px !important;
}

.ant-menu {
  .ant-menu-inline .ant-menu-item:not(:last-child) {
    margin-top: 2px;
  }
}
.menu-icon-size {
  width: 14px;
  height: 14px;
}

.drag-menu-parent {
  .nav-svg{
    color: #0a090a;
    float: left;
    margin: 0px 10px;
    stroke: #fff;
  }
}

.drag-menu-parent {
span.movable-item-name {
  font-size: 13px;
  color: #fff;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1rem;
}
}
.ant-tooltip-placement-top, .ant-tooltip-placement-topLeft, .ant-tooltip-placement-topRight {
  z-index: 999999;
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .expanded-sidebar {
        width: 14% !important;
    }      
}
@media screen and (min-width: 1827px){
  .right-side {
    margin-left: 13px !important;
  }
  ul.ant-menu.ant-menu-dark.ant-menu-root.ant-menu-inline #vertical_menu {
    left: 96%;
}
}
/*************************4k-2560px***********************/

@media screen and (min-width: 2100px) {
  .expanded-sidebar {
  width: 11% !important;
}
}
// ***********************  **********************//
// 0-767 - mobile ****768-1024 -ipad *****1025-above All web********//

/*************************** Both Mobile and iPad **************************/

@media screen and (max-width: 1024px) {
  .side-bar-box {
    box-shadow:none;
      
  }
  .ant-layout-sider-collapsed{
    box-shadow:none;       
  }
  .content {
    background: #f4f9ff;
    flex: 0 0;
    padding: 0px 10px !important;
  }
  .ant-tooltip {
    display: none;
  }
}


/******************************** iPad *************************/

@media screen and (min-width: 768px) and (max-width: 1024px) {

main.ant-layout-content.site-layout-background {
    max-width: 100% !important;
}
ul.ant-menu.ant-menu-dark.ant-menu-root.ant-menu-inline #vertical_menu {
 left: 180px;

 }
}


/************************************* Mobile ***********************************/

@media screen and (max-width: 767px) {  
.expanded-sidebar {
  width: 75% !important;
}
ul.ant-menu.ant-menu-dark.ant-menu-root.ant-menu-inline #vertical_menu {
 left: 93%;
 }
}

