.footer {
  display: table;
  height: 41px;
  font-size: 0.8rem;
  width: 100%;
  background-color: #E6EFFF;
  margin-top:10px;
  text-align: center;
}
.footer-link-style {
  cursor: pointer;
  text-align: center;
  color: #2dbecd;
  margin-top: 20px;
}
.footer-container {
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 999;
}
