//== Component Specific Styles
.highlight-selection {
  li[class='ant-select-selection__choice'] {
    background-color: #2DBECD;
    color: #ffffff;
  }
  span[class='ant-select-selection__choice__remove'] {
    color: #ffffff;
  }
}

.modal-container{
  .ant-select-selection-item{
    background:#FFc832;
  }
}