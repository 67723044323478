.header_bg {
  background: #6c6d6d;
  height: 42px;
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
  .header_title {
    font-size: 16px;
    color: #fff;
    padding: 13px;
    display: inline-block;
    font-weight: 900;
  }
  .add-button {
    float: right;
    margin: 0.3rem 2rem !important;
    background-color: grey;
    min-height: 2rem;
    div {
      display: flex !important;
      margin: 0;
      padding: 0 0.7rem;
      align-items: center;
      font-size: 1rem;
      font-weight: 400;
    }
  }
}
.search_icon_gray {
  .header_title {
    font-size: 16px;
    color: #fff;
    padding: 13px;
    display: inline-block;
    font-weight: 900;
    vertical-align: top;
  }
  .LD__Icon {
    background: #8c8c8c;
    margin: 0.3rem 0;
    border: 1px solid #8c8c8c;
    border-radius: 0.2rem;
    width: 36px;
    height: 34px;
    svg {
      margin: 0.5rem 0.5rem;
      fill: white;
    }
  }
}

.modal-class {
  position: absolute !important;
  top: 8% !important;
  right: -44.5% !important;
  -webkit-transition: all 0ms ease-in-out !important;
  transition: all 0ms ease-in-out !important;
  .ReactModal__Content--after-open {
    overflow: hidden !important;
    height: auto;
  }
}
.compact-view-filter{
  top: 106% !important;
}
.search-bar-icon {
  float: right;
  background: #8c8c8c;
  margin: 0.3rem 0;
  border: 1px solid #8c8c8c;
  border-radius: 0.2rem;
  width: 36px !important;
  height: 34px !important;
  right: 2%;
  svg {
    margin: 5px 5px;
    fill: white;
  }
  &.filter-open{
    background:#2dbecd;
    border:1px solid #2dbecd;
    svg{
      path{
        stroke:white;
        stroke-width:1.8px;
        fill:#2dbecd !important;
      }
    }
  }
}
.search-bar {
  color: black;
  width: 24.1rem;
  transition: width 2s;
  .LD__Icon {
    left: 12px !important;
    top: 8px !important;
  }
}
.LD__Input__input {
  width: 24.1rem;
  min-width: 230px !important;
  padding: 10px 9px 3px 39px !important;
}
.body-content-container {
  margin-bottom: 9%;
}


  // User filter
  .filter-content {
    margin-bottom: 0rem !important;
  }
.accordion-menu .accordion-section:nth-of-type(3) { 
  padding: 12px 0px 5px 0px !important;
}

// .accordion-section div:nth-of-type(2) {
//   padding: 0px 0px 0px 0px !important;
// }
.apply-filter-button {
  margin-bottom: 35px !important;
}

// ---------------------------------------------------

//====================================================================
// 0-767 - mobile ****768-1024 -ipad *****1025-above All web********//
// ===================================================================
/*************************4k-2560px***********************/
@media screen and (min-width: 2560px) { 
  .compact-view-filter {
  top: 49% !important;
  }
}
@media screen and (min-width: 2100px) {
  .header_bg {
    .add-button {     
      margin: 0.3rem 1.5rem !important;
    }
  }
  .ReactModal__Content.ReactModal__Content--after-open {
    position: absolute;
    inset: 2% auto auto 57.2% !important;
}
.search-bar-icon {
  right: 0.5%;
}
}
// **************************Ipad******************************
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .mainsideContainer {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
  }
  .mainsideContainer {  
    .LD__Icon {
      background: none;
      margin: 0.3rem 0;
      border: none;
      border-radius: 0.2rem;
      width: 36px;
      height: 34px;
      svg {
        margin: 0.5rem 0.5rem;
        fill: #2dbecd;
      }
    }
  }

  .hscMjT .LD__Icon {
    -webkit-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    transform: translateY(-10px);
  }

  .sidenav {
    height: 61.5rem;
    width: 307px;
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    background-color: #ffffff;
    overflow-x: hidden;
    transition: 0.3s;
    // padding-top: 60px;
  }
  .rightSidenav {
   position:fixed;
   right: -307px; 
   transition: 0.3s;
  }
  .modal-class { 
    right: 0% !important; 
    top: 0% !important;  
  }
  .ReactModal__Content.ReactModal__Content--after-open {
    max-height: 100% !important;
    min-height: 100% !important;
    width: calc(100% - 26.7rem) !important;
    inset: 0% auto auto 56% !important;
    z-index: 111;
    border: none !important;
    transition: 0.5s !important;
  }
  .accordion-menu {   
    max-height: 100% !important;
  }
  .modal-container {
    height: 100% !important;
  }
  .hscMjT{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    font-size: 0.75rem;
    padding: 10px 15px 10px 15px;
    height: 3.2rem;
    background-color: #F8F8FC;
  }
  .OYaxE {
    padding: 35px 50px 50px;
  }
  .header_bg {
    background: #6c6d6d;
    height: auto;
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
    .add-button {
      float: right;
      margin:-43px 55px 0px 0px !important
    
    }
  }
}

/************************************* Mobile ***********************************/

@media screen and (max-width: 767px) {
  .mainsideContainer {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
  }
  .mainsideContainer {  
    .LD__Icon {
      background: none;
      margin: 0.3rem 0;
      border: none;
      border-radius: 0.2rem;
      width: 36px;
      height: 34px;
      svg {
        margin: 0.5rem 0.5rem;
        fill: #2dbecd;
      }
    }
  }
  .LD__TablePagination {
    display: none !important;
  }
  .hscMjT .LD__Icon {
    -webkit-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    transform: translateY(-10px);
  }
  .sidenav {
    height: 43.5rem;
    width: 300px;
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0px;
    background-color: #ffffff;
    overflow-x: hidden;
    transition: 0.3s;
    // padding-top: 60px;
  }
  .rightSidenav {
   position:fixed;
   right: -300px; 
   transition: 0.3s;
  }
  .modal-class { 
    right: 0% !important; 
    top: 0% !important;  
  }
  .ReactModal__Content.ReactModal__Content--after-open {
    max-height: 100% !important;
    min-height: 100% !important;
    width: calc(100% - 26.7rem) !important;
    inset: 0% auto auto 56% !important;
    z-index: 111;
    border: none !important;
    transition: 0.5s !important;
  }
  .accordion-menu {   
    max-height: 100% !important;
  }
  .modal-container {
    height: 100% !important;
  }
  .hscMjT{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    font-size: 0.75rem;
    padding: 10px 15px 10px 15px;
    height: 3.2rem;
    background-color: #F8F8FC;
  }
  .OYaxE {
    padding: 35px 50px 50px;
  }
  .header_bg {
    background: #6c6d6d;
    height: auto;
    border-top-left-radius: 0.4rem;
    border-top-right-radius: 0.4rem;
    .add-button {
      float: right;
      margin:-43px 10px 0px 0px !important
    }
  }
  .search-bar-div {
    float: right;
      background: #8c8c8c;
      margin: 0.3rem 0;
      border: 1px solid #8c8c8c;
      border-radius: 0.2rem;
      width: 36px !important;
      height: 34px !important;
      right: 2%;
  }
  .search-bar-div.LD__Icon svg {
    margin: 0.5rem 0.5rem;
    fill: white;
  }
  .search-bar {
    width: 100%;
  }
  .search-bar-icon {
    margin-right: 5px;
    svg {
      margin: 8px 8px;
      fill: white;
    }
  }
}

//  -------------- Filter By Popup issue fixed ---------------- //

@media screen and (min-width: 1920px) and (max-width: 2100px) {
.compact-view-filter {
  top: 55% !important;
  right: -57% !important;
}
 }

@media screen and (min-width: 1701px) and (max-width: 1919px) {
  .compact-view-filter {
    top: 75% !important;
    right: -52.5% !important;
  }  
  }

@media screen and (min-width: 1600px) and (max-width: 1700px) {
  .compact-view-filter {
    top: 80% !important;
    right: -48.5% !important;
  }
  }

@media screen and (min-width: 1330px) and (max-width: 1559px) {
  .compact-view-filter {
    top: 95% !important;
}
}

//  -------------- Filter By Popup end ---------------- //