
.truncate {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

.drag-menu-parent{
    display: none;
    position: absolute;
    overflow-x: hidden;
    overflow-y: scroll;
    z-index: 20;
    background: #16698e;
    height: 430px;
    padding-left: 15px;
    padding-right: 5px;
    min-width: 285px;
}

.vertical-dropdown {
    padding: 25px;
    font-size: 12px;
    width: 100%;
    height: 100px;
    color: black;
    margin: 0 auto;
    span {
      font-size: 32px;
      color: #2bdecd;
      cursor: pointer;
    }
  }

// .vertical-collapse-button{
//   margin-left: 65px;
// }

.show {display: block;}

.drag-column {
    height: auto;
    background:#16698e !important;
    overflow-y: scroll;
    overflow-x: hidden;
    display: grid;
    &::-webkit-scrollbar {
        width: 0;
        background: transparent;
    }
}

.first-column {
    background-color: #f5ffea;
}

.second-column {
    background-color: #fffbf5;
}

.movable-item {
    width: 100%;
    border: 1px solid white;
    background: #2dbecd;
    border-radius: 8px;
    margin-bottom: 15px !important;
    height: 48px;
    color: white;
    font-size: 13px;
    padding-top: 14px;
    cursor: pointer;
}

.disabled-menu {
    cursor: not-allowed;
    opacity: 0.4 !important;
}

.drop-here {
    border: 1px dotted white;
    padding-top: 14px;
    height: 48px;
    color: white;
    border-radius: 8px;
    margin-bottom: 15px !important;
}

.drag-menu-header {
    width: 100%;
    display: inline-flex;
    padding-bottom: 8px;
    .drag-menu-title{
        width: 85%;
        margin-top: 10px;
        color: white;
        margin-left: 1px;
}
}

.left-side {
    margin-left: -350px;
    margin-top: -100px;
}


.right-side {
    margin-left: 20px
}


.top-side {
    margin-top: -420px
}


.bottom-side {
    margin-top: 20px;
}

.first-label{
.drag-menu-title {
    font-size: 15px;
    font-weight: 600;
    padding-top: 5px;
}
}

.second-label{
    .drag-menu-title {
        font-size: 15px;
        font-weight: 600;
        padding-bottom: 10px;
    }
}

.device-btns{
    .ant-dropdown-menu-submenu-title{
        .ant-dropdown-menu-submenu-expand-icon{
            display: none;
        }
    }
}

// *********************** Responsive Design **********************//
//=======================================================================

  /************************************* Mobile ***********************************/
  
  @media screen and (max-width: 767px) {  
    .left-side {
        margin-left: -140px; 
    }
    }