.input-div {
  margin-bottom: 20px;
  text-align: left;
  max-width: 100%;
}
label {
  font-size: 0.8rem;
  color: #b1b2b6;
}
.required {
  color: red;

}
.error-message {
  color: #da2a2a;
  font-size: 0.8rem;
}
input:focus {
  border: none;
}
.checkbox-error {
  display: flex;
  flex-direction: column;
}

