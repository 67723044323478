.merck-vibrant-logo {
  // width: 10%;
  // position: relative;
  text-align: right;
  // left: 76rem;
  // padding-right: 20px;
  height: 0px;
  z-index: 999;
}
.merck-vibrant-logo img {
    height: 37px;
    width: 68px;
    position: relative;
    bottom: 35px;
}
.main-content {
  .merck-vibrant-logo img{
    bottom: 60px;
    right: 35px;
  }
}