// // ==========================================================================
// // Modal
// // ==========================================================================

// //== Import global variables and utilities
@import "theme";

.ant-modal-footer {
  padding: 10px 16px;
  text-align: right;
  background: transparent;
  border-radius: 0 0 2px 2px;
  border-top: none !important;
}
.ant-btn-primary {
  background: #2dbecd !important;
  border: #2dbecd;
}
.ant-input {
  border-radius: 6px !important;
}
.input-div {
  margin-bottom: 10px;
}
.input-div label {
  // display: inline-block;
  margin-bottom: 4px;
}
.file-choose {
  width: 20%;
}
.file-input {
  width: 70%;
  margin-right: 5%;
}
.required {
  color: red;
}

.div-cancel {
  padding: 21px 16px 5px 21px;
  text-align: right;
  background: transparent;
  border-radius: 0 0 2px 2px;
  border-top: none !important;
}
.ant-modal-footer {
  display: none;
}
.btn-sucess {
  margin-left: 20px;
  width: 20%;
  cursor: pointer;
}
.hidden-field {
  opacity: 0;
  width: 20%;
  margin-left: -20%;
}
.ant-btn:hover,
.ant-btn:focus {
  color: #fff;
  background: #2dbecd;
  border-color: #2dbecd;
}

.forget-password-text {
  color: #f24949 !important;
  flex: 1 !important;
  text-align: end !important;
  cursor: pointer !important;
}
.error-message {
  color: #f24949 !important;
  font-size: 0.8rem;
}
.ant-modal-content {
  border-radius: 9px;
  display: grid;
}
.ant-modal-close {
  color: #2dbecd !important;
  margin-top: -6px;
}
.ant-modal-header {
  background: #2dbecd !important;
  width: 50% !important;
  align-items: center;
  text-align: center;
  margin-left: 25%;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
.ant-modal-title {
  margin: 0;
  color: white !important;
  font-weight: 600 !important;
  font-size: 1rem !important;
  // letter-spacing: .3px;
  line-height: 10px !important;
  word-wrap: break-word;
}

.ant-modal-wrap, .ant-modal-mask {
  z-index: 999 !important;
}

@media(min-width:768px){
  .ant-modal-wrap {    
    .ant-modal {
      width: 1024px !important;      
    }
  }
}
