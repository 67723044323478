
// ******
// ************************************************************
// == Next Connect variables
// ************************************************************
// *****
$brand-success:                         #139b5f;

$nextconnect-padding-small: 			0.125rem !default;
$nextconnect-padding-5: 				0.3125rem !default;
$nextconnect-padding-12:				0.75rem !default;
$nextconnect-padding-medium: 			0.625rem !default;
$nextconnect-padding-large: 			0.75rem !default;
$nextconnect-margin-small: 				0.125rem !default;
$nextconnect-margin-medium: 			0.625rem !default;
$nextconnect-margin-large: 				0.75rem !default;

// Custom Colors
$nextconnect-primary-color: 			$brand-secondary !default;
$nextconnect-simple-color: 				#ffffff !default;
$nextconnect-simple-background-color: 	$nextconnect-simple-color !default;
$nextconnect-body-background-color: 	#fafafa !default;
$nextconnect-border-color: 				#D1D3D4 !default;
$color-off:                             #e3204d !default;
$default-background-color:              #efefef !default;

// main header
$nextconnect-main-header-lg-height: 50px;
$nextconnect-main-header-md-height: 102px;
$nextconnect-main-header-sm-height: 52px;

//main nav bar
$nextconnect-navbar-height:         47px;

$nextconnect-navbar-box-shadow: 		0px 1px 8px 0px rgba(189,187,189,1) !default;
$nextconnect-navbar-header-margin: 		0.625rem 0.625rem 0.625rem 1.25rem !default;

//side nav bar
$nextconnect-side-navbar-width: 		60px !default;
$nextconnect-side-navbar-border:		1px solid $nextconnect-border-color !default;
$nextconnect-side-navbar-padding-top:	64px !default;

//dashboard
$nextconnect-dashboard-padding-top: 	26px !default;
$nextconnect-dashboard-padding-left:	42px !default;

//card
$nextconnect-card-box-shadow: 			0px 0px 5px 0px rgba(168,168,168,1) !default;
$nextconnect-card-title-padding: 		$nextconnect-padding-medium $nextconnect-padding-large !default;
$nextconnect-card-title-border-radius: 	$border-radius $border-radius 0px 0px !default;

//chart
$nextconnect-chart-container-margin-bottom: 2px !default;
$nextconnect-chart-box-shadow: 			0px 0px 1px 0px rgba(168,168,168,1) !default;

//banner
$nextconnect-cookie-policy-banner-height: 65px;
$nextconnect-cookie-policy-banner-sm-height: 100px;

// footer
$nextconnect-footer-height: 50px;
