@import 'theme';

.modal-footer-container {
  flex-shrink: 0;
  text-align: right;
  padding: .2rem 1.1rem 0.2rem 0;
//   background-color: #f5f5f5;
  margin-bottom: 10px;
  margin-top: 10px;
  button {
    margin-left: 35rem;
    min-width: rem-calc(85);
  }
}
