// ==========================================================================
// Default Theme Variables
// ==========================================================================

// General variable structure
//
// Variable format should follow the `$component-modifier-state-property` order.


// Table of Contents
//
// Colors
// Breakpoints
// Typography
// Spacing
// Body
// Links
// General
// Components

// == Colors

// Grayscale Colors
$gray-dark:                             #333333 !default;
$gray:                                  #545454 !default;
$gray-medium:                           #787878 !default;
$gray-light:                            #A9A9A9 !default;
$gray-lighter:                          #DDDDDD !default;
$gray-lightest:                         #EEEEEE !default;
$gray-x-lightest:                       #FAFAFA !default;

// Brand Color Definitions
$brand-primary:                         #2DBECD !default;
$brand-secondary:                       #503291 !default;;
$brand-success:                         #A5CD50 !default;
$brand-info:                            #96D7D2 !default;
$brand-warning:                         #FFC832 !default;
$brand-danger:                          #E61E50 !default;
$brand-action:                          #EB3C96 !default;


// == Responsive Breakpoints
$screen-sm-min:                         34em !default;
$screen-md-min:                         48em !default;
$screen-lg-min:                         62em !default;
$screen-xl-min:                         75em !default;

$grid-breakpoints: (
  xs: 0,
  sm: 544px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;


// == Typography
$font-family-primary:                   'Helvetica Neue', Helvetica, Arial, sans-serif !default;
$font-family-brand:                     Verdana, sans-serif !default;

$font-weight-light:                     300 !default;
$font-weight-normal:                    normal !default;
$font-weight-medium:                    500 !default;
$font-weight-bold:                      700 !default;



// Pixel value used to responsively scale all typography. Applied to the `<html>` element.
$font-size-root:                        16px !default;

$font-size-base:                        1rem !default;
$font-size-lg:                          1.25rem !default;
$font-size-md:                          .875rem !default;
$font-size-sm:                          .75rem !default;
$font-size-xs:                          .6875rem !default;

$font-size-h1:                          2.5rem !default;
$font-size-h2:                          2rem !default;
$font-size-h3:                          1.5rem !default;
$font-size-h4:                          1.25rem !default;
$font-size-h5:                          1rem !default;
$font-size-h6:                          0.875rem !default;

$line-height-base:                      1.6 !default;


// == Spacing
$global-spacer:                         $font-size-base !default;


// == Body
$body-bg:                               #fff !default;
$body-color:                            $gray-dark !default;


// == Links
$link-color:                            $brand-primary !default;
$link-decoration:                       none !default;
$link-hover-color:                      darken($link-color, 15%) !default;
$link-hover-decoration:                 underline !default;
$global-hover-darken:                   8% !default;
$global-hover-transition:               all .2s ease-in-out !default;


// == General
$border-width:                          1px !default;
$border-radius:                         2px !default;

$global-form-height:                    36px !default;
$global-form-height-sm:                 30px !default;
$global-form-height-lg:                 42px !default;

$global-box-shadow-2:                   0 2px 6px rgba(0,0,0,.1) !default


// ******
// ************************************************************
// == Components
// ************************************************************
// ******


// Alerts
$alert-padding:                         0.5rem 0.9375rem 0.5625rem !default;
$alert-border-radius:                   $border-radius !default;
$alert-border-width:                    $border-width !default;
$alert-bg:                              $gray !default;
$alert-font-size:                       $font-size-sm !default;
$alert-font-weight:                     $font-weight-bold !default;
$alert-text-color:                      #fff !default;
$alert-link-darken:                     $global-hover-darken !default;
$alert-icon-size:                       16px !default;
$alert-icon-margin-right:               0.5rem !default;

$alert-primary-bg:                      $brand-primary !default;
$alert-secondary-bg:                    $brand-secondary !default;
$alert-success-bg:                      $brand-success !default;
$alert-info-bg:                         $brand-info !default;
$alert-warning-bg:                      $brand-warning !default;
$alert-danger-bg:                       $brand-danger !default;
$alert-action-bg:                       $brand-action !default;

$alert-padding-sm:                      0.375rem 0.9375rem 0.4375rem !default;
$alert-font-size-sm:                    $font-size-xs !default;
$alert-icon-size-sm:                    14px !default;

$alert-padding-lg:                      0.625rem 0.9375rem 0.6875rem !default;
$alert-font-size-lg:                    $font-size-md !default;
$alert-icon-size-lg:                    16px !default;

// Banner
$banner-size-base:                      250px !default;
$banner-padding-x:                      2.25rem !default;
$banner-padding-y:                      2.25rem !default;
$banner-margin-bottom:                  ($global-spacer * 2) !default;
$banner-base-bg-color:                  $gray-lightest !default;

$banner-size-sm:                        175px !default;
$banner-size-lg:                        325px !default;


// Buttons
$btn-height:                            $global-form-height !default;
$btn-padding-x:                         1rem !default;
$btn-padding-y:                         0 !default;
$btn-font-size:                         $font-size-md !default;
$btn-font-weight:                       $font-weight-bold !default;
$btn-line-height:                       1 !default;
$btn-min-width:                         30px !default;
$btn-hover-darken:                      $global-hover-darken !default;

$btn-primary-color:                     #fff !default;
$btn-primary-bg:                        $brand-primary !default;
$btn-primary-border:                    $btn-primary-bg !default;

$btn-secondary-color:                     #fff !default;
$btn-secondary-bg:                        $brand-secondary !default;
$btn-secondary-border:                    $btn-secondary-bg !default;

$btn-muted-color:                       $gray-medium !default;
$btn-muted-bg:                          $gray-x-lightest !default;
$btn-muted-border:                      $gray-lighter !default;

$btn-info-color:                        #fff !default;
$btn-info-bg:                           $brand-info !default;
$btn-info-border:                       $btn-info-bg !default;

$btn-success-color:                     #fff !default;
$btn-success-bg:                        $brand-success !default;
$btn-success-border:                    $btn-success-bg !default;

$btn-warning-color:                     #fff !default;
$btn-warning-bg:                        $brand-warning !default;
$btn-warning-border:                    $btn-warning-bg !default;

$btn-danger-color:                      #fff !default;
$btn-danger-bg:                         $brand-danger !default;
$btn-danger-border:                     $btn-danger-bg !default;

$btn-action-color:                      #fff !default;
$btn-action-bg:                         $brand-action !default;
$btn-action-border:                     $btn-action-bg !default;

$btn-link-hover-color:                  $gray-lightest !default;
$btn-link-disabled-color:               $gray-medium !default;

$btn-height-sm:                         $global-form-height-sm !default;
$btn-padding-x-sm:                      0.625rem !default;
$btn-padding-y-sm:                      0 !default;
$btn-font-size-sm:                      $font-size-sm !default;

$btn-height-lg:                         $global-form-height-lg !default;
$btn-padding-x-lg:                      1.5rem !default;
$btn-padding-y-lg:                      0 !default;
$btn-font-size-lg:                      $font-size-base !default;

$btn-height-toolbar:                    30px !default;
$btn-padding-x-toolbar:                 0.3125rem !default;
$btn-padding-y-toolbar:                 0.3125rem !default;
$btn-font-size-toolbar:                 $font-size-md !default;

$btn-block-spacing-y:                   .625rem !default;

$btn-input-group-padding-x:             .75rem !default;


// Card
$card-box-shadow:                       0px 0px 4px 0px rgba(0,0,0,0.15) !default;
$card-content-padding-x:                0.75rem !default;
$card-content-padding-y:                0.75rem !default;
$card-content-simple-padding-x:         0.3125rem !default;
$card-content-simple-padding-y:         0.3125rem !default;

$card-image-aspect-ratio:               56.25% !default;
$card-image-simple-height:              9rem !default;
$card-image-simple-hover:               0 1px 6px rgba(0,0,0,0.12), 0 1px 4px rgba(0,0,0,0.12) !default;

$card-title-font-size:                  0.8125rem !default;
$card-title-margin-bottom:              ($global-spacer / 4) !default;
$card-title-simple-margin-bottom:       ($global-spacer / 8) !default;
$card-title-color:                      $gray-dark !default;

$card-body-font-size:                   $font-size-sm !default;
$card-body-line-height:                 1.5 !default;
$card-body-margin-bottom:               ($global-spacer / 1.75) !default;

$card-list-item-bullet-color:           $gray !default;
$card-list-item-margin-bottom:          0.3125rem !default;

$card-bottom-link-font-size:            $font-size-sm !default;
$card-bottom-link-border-color:         $gray-lighter !default;
$card-bottom-link-padding-x:            $card-content-padding-x !default;
$card-bottom-link-padding-y:            0.5rem !default;

$card-tag-font-size:                    $font-size-sm;
$card-tag-color:                        $gray-medium;


// Carousel
$carousel-nav-position-base:            -30px !default;
$carousel-nav-dot-size:                 1.125rem !default;
$carousel-nav-dot-padding:              0.5rem !default;
$carousel-nav-dot-color-base:           $gray-medium !default;


$carousel-nav-position-inside:          40px !default;
$carousel-nav-dot-color-inside:         #fff !default;


// Dropdown
$dropdown-min-width:                    200px !default;
$dropdown-padding-y:                    0.5rem !default;
$dropdown-bg:                           #fff !default;
$dropdown-border-color:                 $gray-lighter !default;
$dropdown-border-width:                 $border-width !default;
$dropdown-divider-bg:                   #e5e5e5 !default;
$dropdown-box-shadow:                   0 2px 3px rgba(0,0,0,.10) !default;

$dropdown-link-font-size:               $font-size-md !default;
$dropdown-link-color:                   $gray !default;
$dropdown-link-hover-color:             darken($gray-dark, 5%) !default;
$dropdown-link-hover-bg:                #f5f5f5 !default;


$dropdown-link-disabled-color:          $gray-medium !default;


$dropdown-item-padding-y:               6px !default;
$dropdown-item-padding-x:               15px !default;

$dropdown-header-color:                 $gray-medium !default;

$dropdown-link-font-size-sm:            $font-size-sm !default;
$dropdown-item-padding-y-sm:            4px !default;
$dropdown-item-padding-x-sm:            15px !default;


// Floating Button
$floating-btn-size:                     55px !default;
$floating-btn-icon-size:                1.25rem !default;
$floating-btn-box-shadow:               0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12) !default;
$floating-btn-box-shadow-hover:         0 3px 8px 0 rgba(0,0,0,0.2), 0 3px 12px 0 rgba(0,0,0,0.18) !default;

$floating-btn-padding:                  10px !default;

$floating-btn-padding-sm:               0px !default;
$floating-btn-padding-lg:               35px !default;

$floating-btn-size-sm:                  40px !default;
$floating-btn-size-lg:                  65px !default;

$floating-btn-icon-size-sm:             1rem !default;
$floating-btn-icon-size-lg:             1.5rem !default;


// Footer
$footer-bg-color:                       $brand-primary !default;
$footer-font-color:                     #fff !default;
$footer-padding-y:                      ($global-spacer * 2) !default;
$footer-copyright-font-size:            .625rem !default;


// Form Control
$form-group-m-btm-base:                 $global-spacer !default;
$form-group-m-btm-lg:                   $global-spacer !default;
$form-group-m-btm-sm:                   $global-spacer !default;

$form-transition:                       border-color ease-in-out .15s, box-shadow ease-in-out .15s !default;
$cursor-disabled:                       not-allowed !default;


// Gallery
$gallery-margin-bottom:                 0 !default;
$gallery-item-padding-x:                ( $global-spacer / 1.25) !default;
$gallery-item-margin-bottom:            ($global-spacer * 1.75) !default;


// Headings
$h1-font-size:                          $font-size-h1 !default;
$h1-font-weight:                        $font-weight-bold !default;
$h1-line-height:                        1.3 !default;
$h1-margin-bottom:                      ($global-spacer * 2) !default;

$h2-font-size:                          $font-size-h2 !default;
$h2-font-weight:                        $font-weight-bold !default;
$h2-line-height:                        1.3 !default;
$h2-margin-bottom:                      ($global-spacer * 1.25) !default;

$h3-font-size:                          $font-size-h3 !default;
$h3-font-weight:                        $font-weight-normal !default;
$h3-line-height:                        1.4 !default;
$h3-margin-bottom:                      $global-spacer !default;

$h4-font-size:                          $font-size-h4 !default;
$h4-font-weight:                        $font-weight-bold !default;
$h4-line-height:                        1.4 !default;
$h4-margin-bottom:                      $global-spacer !default;

$h5-font-size:                          $font-size-h5 !default;
$h5-font-weight:                        $font-weight-bold !default;
$h5-line-height:                        1.6 !default;
$h5-margin-bottom:                      ($global-spacer / 1.75) !default;

$h6-font-size:                          $font-size-h6 !default;
$h6-font-weight:                        $font-weight-bold !default;
$h6-line-height:                        1.6 !default;
$h6-margin-bottom:                      ($global-spacer / 2) !default;


// Horizontal Rule
$hr-border-width:                       1px !default;
$hr-border-color:                       rgba(0,0,0,.1) !default;
$hr-spacing-base:                       $global-spacer !default;

$hr-spacing-xs:                         0 !default;
$hr-spacing-sm:                         ($global-spacer / 1.5) !default;
$hr-spacing-lg:                         ($global-spacer * 2) !default;
$hr-spacing-xl:                         ($global-spacer * 3) !default;


// Icon
$icon-positioned-padding:               4px !default;
$icon-font-size-sm:                     $font-size-md !default;
$icon-font-size-md:                     $font-size-base !default;
$icon-font-size-lg:                     $font-size-lg !default;


// Inputs
$input-height:                          $global-form-height !default;
$input-padding-x:                       0.75rem !default;
$input-padding-y:                       0.5rem !default;
$input-line-height:                     1.25 !default;

$input-bg:                              #fff !default;
$input-bg-disabled:                     $gray-lighter !default;

$input-color:                           $gray !default;
$input-border-color:                    $gray-lighter !default;
$input-btn-border-width:                $border-width !default;

$input-border-radius:                   $border-radius !default;
$input-border-focus-color:              $brand-primary !default;
$input-border-error-color:              $brand-danger !default;

$input-color-placeholder:               #a9a9a9 !default;

$input-height-sm:                       $global-form-height-sm !default;
$input-padding-x-sm:                    0.75rem !default;
$input-padding-y-sm:                    .375rem !default;

$input-height-lg:                       $global-form-height-lg !default;
$input-padding-x-lg:                    0.75rem !default;
$input-padding-y-lg:                    .625rem !default;

$input-label-font-size-sm:              $font-size-sm !default;
$input-label-font-size-lg:              $font-size-md !default;
$input-label-margin-bottom:             .125rem !default;

$input-description-color:               $gray-medium !default;
$input-description-font-size-sm:        $font-size-xs !default;

$input-error-text-color:                $brand-danger !default;
$input-error-text-font-size:            $font-size-sm !default;


// AddOn Styles
$input-addon-padding-x:                 $btn-padding-x !default;
$input-addon-padding-y:                 $btn-padding-y !default;
$input-addon-font-size:                 $btn-font-size !default;
$input-addon-font-weight:               $btn-font-weight !default;
$input-addon-bg:                        $gray-lightest !default;
$input-addon-text-color:                $gray-medium !default;

$input-addon-font-size-sm:              $font-size-sm !default;
$input-addon-padding-x-sm:              $input-padding-x-sm !default;
$input-addon-padding-y-sm:              $input-padding-y-sm !default;

$input-addon-font-size-lg:              $font-size-base !default;
$input-addon-padding-x-lg:              $input-padding-x-lg !default;
$input-addon-padding-y-lg:              $input-padding-y-lg !default;


// List Group
$list-group-font-size:                  $font-size-md !default;
$list-group-inline-spacing:             1.25rem !default;
$list-group-inverse-color:              #fff !default;

$list-group-font-size-sm:               $font-size-sm !default;
$list-group-font-size-lg:               $font-size-base !default;


// List Group Icon
$list-group-icon-size:                  1.875rem !default;
$list-group-icon-spacing:               0.625rem !default;

$list-group-icon-size-sm:               1.25rem !default;
$list-group-icon-spacing-sm:            0.5rem !default;

$list-group-icon-size-lg:               2.5rem !default;
$list-group-icon-spacing-lg:            0.625rem !default;


// Loader
$loader-size-base:                      75px !default;

$loader-size-sm:                        50px !default;
$loader-size-lg:                        100px !default;


// Modal
$modal-bg:                              rgba(255,255,255, 0.85) !default;
$modal-border-color:                    $gray-lighter !default;
$modal-box-shadow:                      0px 0px 6px 0px rgba(0,0,0,0.3) !default;

$modal-title-font-size:                 $font-size-md !default;
$modal-title-color:                     #fff !default;
$modal-close-color:                     rgba(255,255,255,0.9) !default;
$modal-close-color-hover:               #fff !default;

$modal-bg-inverse:                      rgba(0,0,0, 0.75) !default;


// Select **from react select**

// control options
$select-input-bg:                       #fff !default;
$select-input-bg-disabled:              $gray-medium !default;
$select-input-border-color:             $input-border-color !default;
$select-input-border-radius:            $border-radius !default;
$select-input-border-focus:             $input-border-focus-color !default;
$select-input-border-width:             $input-btn-border-width !default;
$select-input-height:                   $btn-height !default;
$select-input-internal-height:          ($select-input-height - ($select-input-border-width * 2)) !default;
$select-input-placeholder:              #a9a9a9 !default;
$select-text-color:                     $input-color !default;
$select-text-size:                      $font-size-md !default;
$select-link-hover-color:               $select-input-border-focus !default;

$select-padding-vertical:               0.5rem !default;
$select-padding-horizontal:             $input-padding-x !default;

// menu options
$select-menu-zindex:                    1000 !default;
$select-menu-max-height:                200px !default;

$select-text-size:                      $font-size-md !default;
$select-option-color:                   lighten($select-text-color, 20%) !default;
$select-option-bg:                      $select-input-bg !default;
$select-option-focused-color:           $select-text-color !default;
$select-option-focused-bg:              $gray-lightest !default;
$select-option-disabled-color:          lighten($select-text-color, 60%) !default;

$select-noresults-color:                lighten($select-text-color, 40%) !default;

// clear "x" button
$select-clear-size:                     floor(($select-input-height / 2)) !default;
$select-clear-color:                    #999 !default;
$select-clear-hover-color:              $brand-danger !default;
$select-clear-width:                    ($select-input-internal-height / 2) !default;

// arrow indicator
$select-arrow-color:                    $brand-primary !default;
$select-arrow-color-hover:              $brand-primary !default;
$select-arrow-width:                    5px !default;

// loading indicator
$select-loading-size:                   16px !default;
$select-loading-color:                  $select-text-color !default;
$select-loading-color-bg:               $select-input-border-color !default;

// multi-select item
$select-item-border-radius:             2px !default;
$select-item-gutter:                    5px !default;
$select-item-padding-vertical:          2px !default;
$select-item-padding-horizontal:        5px !default;
$select-item-font-size:                 .9em !default;
$select-item-color:                     #08c !default; // pale blue
$select-item-bg:                        #f2f9fc !default;
$select-item-border-color:              darken($select-item-bg, 10%) !default;
$select-item-hover-color:               darken($select-item-color, 5%) !default; // pale blue
$select-item-hover-bg:                  darken($select-item-bg, 5%) !default;
$select-item-disabled-color:            #333 !default;
$select-item-disabled-bg:               #fcfcfc !default;
$select-item-disabled-border-color:     darken($select-item-disabled-bg, 10%) !default;

// Size Variables
$select-text-size-sm:                   $font-size-sm !default;
$select-text-size-lg:                   $font-size-md !default;

$select-input-height-sm:                $global-form-height-sm !default;
$select-input-height-lg:                $global-form-height-lg !default;

$select-padding-vertical-sm:            0.3125rem !default;
$select-padding-horizontal-sm:          $input-padding-x-sm !default;

$select-padding-vertical-lg:            0.5rem !default;
$select-padding-horizontal-lg:          $input-padding-x-lg !default;

$select-input-internal-height-sm:       ($select-input-height-sm - ($select-input-border-width * 2)) !default;
$select-input-internal-height-lg:       ($select-input-height-lg - ($select-input-border-width * 2)) !default;


// Panel
$panel-margin-bottom:                   $global-spacer !default;
$panel-padding:                         $global-spacer !default;

$panel-white-bg:                        #fff !default;
$panel-gray-dark-bg:                    $gray-dark !default;
$panel-gray-bg:                         $gray !default;
$panel-gray-medium-bg:                  $gray-medium !default;
$panel-gray-light-bg:                   $gray-light !default;
$panel-gray-lighter-bg:                 $gray-lighter !default;
$panel-gray-lightest-bg:                $gray-lightest !default;
$panel-gray-x-lightest-bg:              $gray-x-lightest !default;
$panel-primary-bg:                      $brand-primary !default;
$panel-secondary-bg:                    $brand-secondary !default;
$panel-success-bg:                      $brand-success !default;
$panel-info-bg:                         $brand-info !default;
$panel-warning-bg:                      $brand-warning !default;
$panel-danger-bg:                       $brand-danger !default;
$panel-action-bg:                       $brand-action !default;



// Popovers
$popover-bg:                            #fff !default;
$popover-border-width:                  $border-width !default;
$popover-border-color:                  $gray-lighter;
$popover-margin-top:                    10px !default;
$popover-box-shadow:                    $global-box-shadow-2 !default;

$popover-title-padding-x:               6px !default;
$popover-title-padding-y:               15px !default;
$popover-title-font-size:               $font-size-md !default;
$popover-title-font-weight:             $font-weight-bold !default;
$popover-title-color:                   $gray-medium !default;
$popover-title-bg-color:                $gray-lightest !default;

$popover-title-primary-bg:              $brand-primary !default;
$popover-title-secondary-bg:            $brand-secondary !default;
$popover-title-muted-bg:                $gray-lightest !default;
$popover-title-success-bg:              $brand-success !default;
$popover-title-info-bg:                 $brand-info !default;
$popover-title-warning-bg:              $brand-warning !default;
$popover-title-danger-bg:               $brand-danger !default;
$popover-title-action-bg:               $brand-action !default;

$popover-content-padding-x:             15px !default;
$popover-content-padding-y:             15px !default;

$popover-arrow-margin-top:              15px !default;
$popover-arrow-offset:                  35px !default;
$popover-arrow-width:                   10px !default;
$popover-arrow-color:                   $popover-bg !default;

$popover-arrow-outer-width:             ($popover-arrow-width + 1px) !default;
$popover-arrow-outer-color:             fade-in($popover-border-color, .05) !default;

$popover-icon-trigger-offset:           5px !default;


// TabBar
$tab-bar-bg-color:                      $brand-primary !default;
$tab-bar-text-color:                    #fff !default;
$tab-bar-font-size:                     $font-size-md !default;
$tab-bar-bottom-spacing:                $global-spacer !default;
$tab-bar-box-shadow:                    0 2px 3px rgba(0,0,0,.1) !default;

$tab-bar-item-padding-y:                0.375rem !default;
$tab-bar-item-padding-x:                0.9375rem !default;
$tab-bar-item-active-border-color:      $brand-warning !default;
$tab-bar-item-active-border-width:      0.125rem !default;
$tab-bar-item-hover-color:              darken($tab-bar-bg-color, 4%) !default;


// Tooltip
$tooltip-max-width:                     200px !default;
$tooltip-color:                         #fff !default;
$tooltip-bg:                            $gray-dark !default;
$tooltip-font-size:                     $font-size-sm;
$tooltip-font-weight:                   $font-weight-bold;
$tooltip-padding-y:                     6px !default;
$tooltip-padding-x:                     15px !default;
$tooltip-margin:                        3px !default;

$tooltip-arrow-width:                   7px !default;
$tooltip-arrow-color:                   $tooltip-bg !default;

$tooltip-primary-bg:                    $brand-primary !default;
$tooltip-primary-color:                 #fff !default;
$tooltip-secondary-bg:                  $brand-secondary !default;
$tooltip-secondary-color:               #fff !default;
$tooltip-success-bg:                    $brand-success !default;
$tooltip-success-color:                 #fff !default;
$tooltip-info-bg:                       $brand-info !default;
$tooltip-info-color:                    #fff !default;
$tooltip-warning-bg:                    $brand-warning !default;
$tooltip-warning-color:                 #fff !default;
$tooltip-danger-bg:                     $brand-danger !default;
$tooltip-danger-color:                  #fff !default;
$tooltip-action-bg:                     $brand-action !default;
$tooltip-action-color:                  #fff !default;


// Z-index master list
$zindex-dropdown-backdrop:              990 !default;
$zindex-navbar:                         1000 !default;
$zindex-dropdown:                       1000 !default;
$zindex-popover:                        1060 !default;
$zindex-tooltip:                        1070 !default;
$zindex-navbar-fixed:                   1030 !default;
$zindex-navbar-sticky:                  1030 !default;
$zindex-modal-bg:                       1040 !default;
$zindex-modal:                          1050 !default;
$zindex-loading:                        1060 !default;
