.custom-field {
  margin: 0 0 0 0.313rem;
  min-width: 3.125rem;
  max-width: 10rem;
  vertical-align: top;
  border: 1px solid aqua;
  margin-bottom: 2px;
  border-radius: 5px;
  height: 35px;
}
.custom-field:focus {
  border: 2px solid aqua;
  outline: none;
}