// ==========================================================================
// Icon
// ==========================================================================

//== Import global variables and utilities

@import "theme";

.icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  fill: currentColor;
  color: currentColor;
  vertical-align: text-top;
  transition: background-color .1s ease-in-out;
}

.has-action {
  cursor: pointer;
}

.has-positioning {
  position: relative;
}

// Size Classes
.icon-sm {
  width: 14px;
  height: 14px;
}

.icon-lg {
  width: 18px;
  height: 18px;
}

// Style Classes
.icon-black {
  fill: $gray-dark;
}
.icon-white {
  fill: #fff;
}
.icon-primary {
  fill: $brand-primary;
  &:hover {
    fill: darken($brand-primary, $global-hover-darken);
  }
  &.active {
    fill: $brand-primary
  }
  &.disabled {
    fill: $gray-dark;
    opacity: 0.25;
    cursor: auto;
    &:hover {
      color: $gray-dark;
    }
  }
}
.icon-muted {
  fill: $gray-medium;
  &:hover {
    fill: darken($gray-medium, $global-hover-darken);
  }
  &.active {
    fill: $brand-primary
  }
  &.disabled {
    opacity: 0.25;
    cursor: auto;
    &:hover {
      color: $gray-dark;
    }
  }
}
.icon-info {
  fill: $brand-info;
}
.icon-success {
  fill: $brand-success;
}
.icon-warning {
  fill: $brand-warning;
}
.icon-danger {
  fill: $brand-danger;
}
.icon-action {
  fill: $brand-action;
}
.icon-success-dark {
  //fill: $nextconnect-success-dark;
}
.icon-gray-light {
  fill: $gray-light;
}
