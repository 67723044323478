.notifcation-title{
  font-weight: bold;
  padding: 1px 25px;
}

.notfcn-content{
  display: flex;
  border: 1px solid #E1E1EA;
  .detail-col{
    border-left: 1px solid #E1E1EA;
  }

  .half-container{
    width: 35vw;
  }
  .ant-input-affix-wrapper-focused, .ant-input-affix-wrapper:focus{
    border-bottom: 2px solid #31afbc !important;
    box-shadow: none;
  }
}

.notfication-pagination {
  div:first-child span:nth-child(3) {
    margin-left: 0;
    margin-right: 2px;
  }
  & > div:nth-child(2) {
    margin-left: 10px;
  }
}

.empty-notfcn-list{
  margin: 20px;
  text-align: center
}

.detail-main-div{
  font-size: 14px;
  .detail-header{
    margin:20px;
    display: flex;
    flex-direction: row;
    img{
      height: 30px;
      width: 30px;
    }
    .name-div{
      padding-left: 20px;
      span{
        font-weight: bold;
      }
      .timestamp-span{
        padding-top: 2px;
        color: #A9A9BA;
      }
    }
  }
  .detail-div{
    border-top: 1px solid #E4E8EE;
    padding: 10px 23px;
    p{
      font-weight: bold;
    }
  }
}

