.filter-content {
  & {
    position: relative;
    display: block;
    margin-bottom: 1rem;
  }
  &.disabled > .filter-label {
    color: rgba(120, 120, 120, 0.5)
  }
  
}
.date-range-filter-label {
  display: block;
  padding-top: 5px;
  font-size: .875rem;
  color: #787878;
}

.filter-content {
 .LD__TextField {
   .LD__YearInput {
          width: 50%;
       }
   }
}

.nem-calender {
  .LD__DatePicker__Calendar {
    top: 80px;
  }
}
.nem-calender {
  .LD__DatePicker__Input {
    .LD__TextField {
      width: 140px;
    }
  }
}
// For Calendar scroll related issue fix

.filter-content section.LD__Calendar {
  padding: 20px 0px 13px;
  width: 350px;
}

.filter-content section.LD__Calendar section div+div {
  padding: 0px 6px;
  margin-right: 0px;
  margin-left: 0px;
}