.input-div {
  margin-bottom: 20px;
  text-align: left;
  max-width: 100%;
  label {
    font-size: 0.8rem;
    color:  #606060;
    font-family: 'Lato'
  }
}
.input-text {
  background-color: #f8f8fc;
  height: 34px;
  text-indent: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.required {
  color: red;
}
.error-message {
  color: #da2a2a;
  font-size: 0.8rem;
}
input:focus {
  border: none;
}
.phone-number {
  flex: 1 !important;
  max-width: 56% !important;
  margin-top: 22px !important;
}
input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #2dbecd;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  background-color: #2dbecd;
  border-radius: 3px;
  flex: 1;
  font-size: 0.9rem;
  font-weight: 700;
  color: white !important;
  font-family: 'Lato'
}
.input-file {
  flex: 3;
  margin-right: 2rem;
  background-color: #f8f8fc;
  height: 34px;
  text-indent: 10px;
}
.input-file-div {
  display: flex;
  justify-content: space-between;
}
input:read-only {
  background-color: #d9d9d9;
}
.input-file {
  background-color: white !important;
}

.text-placeholder {
  font-size: 12px;
}
.ant-input-disabled {
  background: #ddd !important;
}



// *********************** Responsive Design **********************//
//=======================================================================

/************************************* Mobile ***********************************/

@media screen and (max-width: 767px) {

  .input-file-div{
    .news-feed {
      margin-right: 1rem;
      height: 38px;
    }
    .news-feed-file{
      font-size: 0.825rem;
      text-align: center;
      line-height: initial;
    }
  }
  .custom-file-upload {
    padding: 3px 12px;
  }
}