.tooltip-wrap {
  white-space: nowrap;
  overflow: hidden;
  height: 1rem;
  text-overflow: ellipsis;
}

.tooltip-wrap-consumables {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 35rem;
}
