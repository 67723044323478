.unauthorized__div {
    height: 70vh;
    display:flex;
    justify-content: center;
    align-items: center;
    h2 {
        margin-left: 1.5rem;
        font-size: 1.8rem;
    }
}
.goto-home {
    position: absolute;
    left: 48%;
}