.input-div {
  margin-bottom: 20px;
  text-align: left;
  max-width: 100%;
  label {
    font-size: 0.8rem;
    color:  #606060;
    font-family: 'Lato'
  }
}

.required {
  color: red;
}
.error-message {
  color: #da2a2a;
  font-size: 0.8rem;
}
input:focus {
  border: none;
}
.ant-select-multiple {
  border-radius: 6px;
  width: 100% !important;
}
.select-label-name {
  color: #000000;
  font-size: 15px;
}
.ant-select-disabled {
  background: #ddd !important;
}
