@import "theme";
.button-bar {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  [type="button"],
  [type="submit"] {
    min-width: 5rem;
    @include media-breakpoint-down(sm, $grid-breakpoints) {
      width: 100%;
      margin: 0 0 0.9375rem 0;
    }
  }
  [type="button"] {
    margin: 0 0.9375rem 0 0;
    @include media-breakpoint-down(sm, $grid-breakpoints) {
      margin: 0 0 0.9375rem 0;
    }
  }
  [disabled] {
    cursor: not-allowed;
  }
}
